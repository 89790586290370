<form *ngIf="formGroup else loading" [formGroup]="formGroup">
    <div class="transition-form">
        <nuc-form-field label="Name">
            <nuc-input formControlName="name" placeholder="Enter name" required></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Title">
            <nuc-input formControlName="title" placeholder="Enter title"></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Step from">
            <nuc-dropdown formControlName="from"
                          [total]="steps.length"
                          [items]="steps"
                          placeholder="Select a step">
            </nuc-dropdown>
        </nuc-form-field>

        <nuc-form-field label="Step to">
            <nuc-dropdown formControlName="to"
                          [total]="steps.length"
                          [items]="steps"
                          placeholder="Select a step">
            </nuc-dropdown>
        </nuc-form-field>
    </div>

    <div class="recipe-form-array">
        <h2>Recipe tasks</h2>
        <p>Optional, add one or more recipe tasks to the transition</p>
        <hr>

        <div class="task" *ngFor="let taskFormGroup of recipeFormArray?.controls; let taskIndex = index">

            <div class="task-header">
                <p>#{{taskIndex + 1}}.</p>
                <nuc-button-secondary icon="nucicon_trash_fill" (click)="deleteRecipeTask(taskIndex)"></nuc-button-secondary>
            </div>

            <!--standard fields for all recipe, name & variants(optional)-->
            <div [formGroup]="taskFormGroup" class="fields-row">
                <nuc-form-field label="Task">
                    <nuc-dropdown formControlName="name"
                                  placeholder="Select recipe task"
                                  [searchable]="true"
                                  [nullOption]="false"
                                  (requestData)="getRecipeTasks($event)"
                                  [total]="recipeTaskOptions.length"
                                  [items]="recipeTaskOptions"
                                  [required]="true">
                    </nuc-dropdown>
                    <nuc-error *ngIf="taskFormGroup.errors">{{taskFormGroup.errors.message}}</nuc-error>
                </nuc-form-field>
                <nuc-form-field *ngIf="taskFormGroup.controls.variants" label="Variants">
                    <nuc-dropdown-multiselect
                        formControlName="variants"
                        placeholder="Select specific variants"
                        [items]="variants">
                    </nuc-dropdown-multiselect>
                </nuc-form-field>
            </div>

            <!--recipe task specific fields => parameters-->
            <form *ngIf="taskFormGroup.controls.parameters" [ngSwitch]="taskFormGroup.value.name?.task" [formGroup]="taskFormGroup.controls.parameters">

                <div *ngSwitchCase="ECustomWorkflowTransitionRecipeTaskName.SEND_NOTIFICATIONS" class="fields-row">
                    <nuc-form-field label="Notification type">
                        <nuc-dropdown formControlName="notificationType"
                                      [nullOption]="false"
                                      [items]="notificationTypeOptions"
                                      placeholder="Choose notification type"></nuc-dropdown>
                    </nuc-form-field>
                    <nuc-form-field label="Roles">
                        <nuc-dropdown-multiselect formControlName="roles"
                                                  [items]="roles"
                                                  placeholder="Choose roles"></nuc-dropdown-multiselect>
                    </nuc-form-field>
                </div>

                <ng-container *ngSwitchCase="ECustomWorkflowTransitionRecipeTaskName.REJECT_RULES">
                    <div class="fields-row">
                        <nuc-form-field label="Title">
                            <nuc-input formControlName="title" placeholder="Enter title"></nuc-input>
                        </nuc-form-field>

                        <nuc-form-field label="Rejection message">
                            <nuc-input formControlName="rejectionMessage" placeholder="Enter rejection message"></nuc-input>
                        </nuc-form-field>

                        <nuc-form-field label="Collection">
                            <nuc-dropdown formControlName="collection"
                                          [items]="collectionOptions"
                                          [nullOption]="false"
                                          placeholder="Choose collection"></nuc-dropdown>
                        </nuc-form-field>
                    </div>

                    <!--here we loop through the config-->
                    <div class="subsection">

                        <div class="subsection-header">

                            <div class="subsection-info">
                                <h2>Details</h2>
                                <p>Define the config</p>
                            </div>
                        </div>

                        <div *ngFor="let ruleForm of taskFormGroup.controls.parameters.controls.rules.controls; let ruleIndex = index" class="subsection-item">

                            <nuc-button-bar>
                                <nuc-button-secondary icon="nucicon_trash_fill" (click)="deleteFormFromFormArrayByName('rules', ruleIndex, taskIndex)"></nuc-button-secondary>
                            </nuc-button-bar>

                            <ng-container [formGroup]="ruleForm">
                                <nuc-form-field label="Name">
                                    <nuc-input formControlName="name" placeholder="Enter name"></nuc-input>
                                </nuc-form-field>

                                <div class="subsection">

                                    <div class="subsection-header">

                                        <div class="subsection-info">
                                            <h2>Conditions</h2>
                                            <p>Define the conditions of the config</p>
                                        </div>

                                    </div>

                                    <div class="subsection-item" *ngFor="let conditionGroup of ruleForm.controls.conditions.controls; let conditionIndex = index">

                                        <nuc-button-bar>
                                            <nuc-button-secondary icon="nucicon_trash_fill" (click)="deleteConditionFromRule(conditionIndex, ruleIndex, taskIndex)"></nuc-button-secondary>
                                        </nuc-button-bar>

                                        <div class="fields-row" [formGroup]="conditionGroup">
                                            <nuc-form-field label="Property">
                                                <nuc-dropdown formControlName="property"
                                                              [items]="allowedProperties"
                                                              [nullOption]="false"
                                                              placeholder="Choose property"></nuc-dropdown>
                                            </nuc-form-field>

                                            <nuc-form-field label="Type">
                                                <nuc-dropdown formControlName="type"
                                                              [items]="valueTypeOptions"
                                                              [nullOption]="false"
                                                              placeholder="Choose type"></nuc-dropdown>
                                            </nuc-form-field>

                                            <nuc-form-field label="Value">
                                                <nuc-dropdown formControlName="value"
                                                              [items]="stickyNoteStatusOptions"
                                                              [nullOption]="false"
                                                              placeholder="Choose value"></nuc-dropdown>
                                            </nuc-form-field>
                                        </div>

                                    </div>

                                    <nuc-button-bar>
                                        <nuc-button-secondary
                                            icon="nucicon_add"
                                            nucTooltip="Add condition"
                                            (click)="addConditionFormToRule(ruleIndex, taskIndex)"></nuc-button-secondary>
                                    </nuc-button-bar>
                                </div>

                            </ng-container>
                        </div>

                        <nuc-button-bar>
                            <nuc-button-secondary
                                icon="nucicon_add"
                                nucTooltip="Add rule"
                                (click)="addFormToFormArrayByName('rules', taskIndex)"></nuc-button-secondary>
                        </nuc-button-bar>

                    </div>
                </ng-container>

                <div *ngSwitchCase="ECustomWorkflowTransitionRecipeTaskName.REJECT_SIGN_OFF_ROLES" class="fields-row">
                    <nuc-form-field label="Title">
                        <nuc-input formControlName="title" placeholder="Enter title"></nuc-input>
                    </nuc-form-field>
                    <nuc-form-field label="Rejection message">
                        <nuc-input formControlName="rejectionMessage" placeholder="Enter rejection message"></nuc-input>
                    </nuc-form-field>
                    <nuc-form-field label="Roles">
                        <nuc-dropdown-multiselect formControlName="roles"
                                                  [items]="roles"
                                                  placeholder="Choose roles"></nuc-dropdown-multiselect>
                    </nuc-form-field>
                    <nuc-form-field label="Threshold">
                        <nuc-input formControlName="threshold" placeholder="Enter threshold" type="number" [max]="100" [min]="1"></nuc-input>
                    </nuc-form-field>
                </div>

                <ng-container *ngSwitchCase="ECustomWorkflowTransitionRecipeTaskName.UPLOAD_ASSETS">
                    <div class="fields-row">
                        <nuc-form-field label="Join">
                            <nuc-input formControlName="join" placeholder="Enter a separator to join the fragments" required></nuc-input>
                        </nuc-form-field>

                        <!--TODO：jiamei fix this, ask Bogdan-->
                        <div class="sequence-control">
                            <nuc-form-field label="Sequence"></nuc-form-field>
                            <!--text and textPosition is used to fix styling-->
                            <nuc-checkbox formControlName="sequence" text=" " textPosition="start"></nuc-checkbox>
                        </div>
                    </div>

                    <div class="subsection">

                        <div class="subsection-header">

                            <div class="subsection-info">
                                <h2>Fragments</h2>
                                <p>Define the fragments of the identifier</p>
                            </div>
                        </div>

                        <div class="subsection-item" *ngFor="let fragmentGroup of taskFormGroup.controls.parameters.controls.fragments.controls; let fragmentIndex = index">

                            <nuc-button-bar>
                                <nuc-button-secondary icon="nucicon_trash_fill" (click)="deleteFormFromFormArrayByName('fragments', fragmentIndex, taskIndex)"></nuc-button-secondary>
                            </nuc-button-bar>

                            <workflow-identifier-setting-fragment-form-component
                                [formGroup]="fragmentGroup"
                                [properties]="uploadAssetsProperties"
                                [fragmentTypeOptions]="fragmentTypeOptions">
                            </workflow-identifier-setting-fragment-form-component>

                        </div>

                        <nuc-button-bar>
                            <nuc-button-secondary
                                icon="nucicon_add"
                                nucTooltip="Add fragment"
                                (click)="addFormToFormArrayByName('fragments', taskIndex)"></nuc-button-secondary>
                        </nuc-button-bar>
                    </div>
                </ng-container>

                <div *ngSwitchCase="ECustomWorkflowTransitionRecipeTaskName.CREATE_LINKS" class="fields-row">
                    <nuc-form-field label="Permissions">
                        <nuc-dropdown-multiselect formControlName="permissions"
                                                  [searchable]="true"
                                                  (requestData)="requestPermissionOptions($event)"
                                                  [items]="permissionOptions"
                                                  placeholder="Choose permissions"></nuc-dropdown-multiselect>
                    </nuc-form-field>
                    <nuc-form-field label="Link">
                        <nuc-input formControlName="link" placeholder="Enter link"></nuc-input>
                    </nuc-form-field>
                    <nuc-form-field label="Time before expiration">
                        <nuc-input type="number" formControlName="linkExpiration" placeholder="Link expiration in seconds"/>
                    </nuc-form-field>
                </div>

                <ng-container *ngSwitchCase="ECustomWorkflowTransitionRecipeTaskName.SEND_LINKS">
                    <nuc-form-field label="Message">
                        <nuc-textarea formControlName="message" placeholder="Enter message"></nuc-textarea>
                    </nuc-form-field>

                    <div class="subsection">

                        <div class="subsection-header">
                            <div class="subsection-info">
                                <h2>Receiver(s)</h2>
                                <p>Define who should receive the email</p>
                            </div>
                        </div>

                        @for (receiverForm of taskFormGroup.controls.parameters.controls.receivers.controls; track receiverForm; let receiverIndex = $index) {
                            <workflow-configuration-receiver-form-component
                                [receiverForm]="receiverForm"
                                [receiverIndex]="receiverIndex"
                                [roles]="roles"
                                [propertyOptions]="propertyOptions"
                                [propertyControlOptions]="propertyControlOptions"
                                (deleteClicked)="deleteFormFromFormArrayByName('receivers', receiverIndex, taskIndex)">
                            </workflow-configuration-receiver-form-component>
                        }


                        <nuc-button-bar>
                            <nuc-button-secondary
                                icon="nucicon_add"
                                nucTooltip="Add receiver"
                                (click)="addFormToFormArrayByName('receivers', taskIndex)"></nuc-button-secondary>
                        </nuc-button-bar>
                    </div>

                    <div class="subsection">

                        <div class="subsection-header">
                            <div class="subsection-info">
                                <h2>Details</h2>
                                <p>Define the data you wish to show in the email</p>
                            </div>
                        </div>

                        <div class="subsection-item" *ngFor="let detailForm of taskFormGroup.controls.parameters.controls.details.controls; let detailIndex = index">

                            <nuc-button-bar>
                                <nuc-button-secondary icon="nucicon_trash_fill" (click)="deleteFormFromFormArrayByName('details', detailIndex, taskIndex)"></nuc-button-secondary>
                            </nuc-button-bar>

                            <div [formGroup]="detailForm" class="fields-row">
                                <nuc-form-field label="Label">
                                    <nuc-input formControlName="label" placeholder="Enter label"></nuc-input>
                                </nuc-form-field>
                                <nuc-form-field label="Path">
                                    <property-control
                                        formControlName="path"
                                        [ignoreVariant]="true"
                                        [ruleProperties]="propertyOptions"/>
                                </nuc-form-field>
                            </div>
                        </div>

                        <nuc-button-bar>
                            <nuc-button-secondary
                                icon="nucicon_add"
                                nucTooltip="Add detail"
                                (click)="addFormToFormArrayByName('details', taskIndex)"></nuc-button-secondary>
                        </nuc-button-bar>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="ECustomWorkflowTransitionRecipeTaskName.UPDATE_INDESIGN_CONTENT">
                      <div class="subsection">
                          <div class="subsection-header">
                              <div class="subsection-info">
                                  <h2>Values</h2>
                                  <p>Specify which fields should be updated in the InDesign file</p>
                              </div>
                          </div>

                          <div class="subsection-item" *ngFor="let valueForm of taskFormGroup.controls.parameters.controls.values.controls; let valueIndex = index;">
                              <nuc-button-bar>
                                  <nuc-button-secondary
                                      icon="nucicon_trash_fill"
                                      (click)="deleteFormFromFormArrayByName('values', valueIndex, taskIndex)">
                                  </nuc-button-secondary>
                              </nuc-button-bar>

                              <nuc-form-field [formGroup]="valueForm" class="property" label="Property">
                                  <property-control
                                      class="property"
                                      formControlName="property"
                                      [ruleProperties]="updateInDesignContentProperties"/>
                              </nuc-form-field>
                          </div>

                          <nuc-button-bar>
                              <nuc-button-secondary
                                  icon="nucicon_add"
                                  nucTooltip="Add value"
                                  (click)="addFormToFormArrayByName('values', taskIndex)"></nuc-button-secondary>
                          </nuc-button-bar>
                      </div>
                </ng-container>

                <div *ngSwitchCase="ECustomWorkflowTransitionRecipeTaskName.AUTO_LAYOUT">
                    <h2>Briefing item area size</h2>
                    <div class="fields-row">
                        <nuc-form-field label="Column span">
                            <nuc-input formControlName="columnSpan" placeholder="Enter number" type="number"></nuc-input>
                        </nuc-form-field>
                        <nuc-form-field label="Row span">
                            <nuc-input formControlName="rowSpan" placeholder="Enter number" type="number">></nuc-input>
                        </nuc-form-field>
                    </div>
                </div>
            </form>
        </div>

        <nuc-button-bar>
            <nuc-button-secondary text="Add recipe task" icon="nucicon_add" iconPosition="end" (click)="addTaskFormGroup()"></nuc-button-secondary>
        </nuc-button-bar>
    </div>
</form>

<ng-template #loading>
    <rl-loading-indicator></rl-loading-indicator>
</ng-template>
