import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RLValidatorConstants} from '../../../classes/validators/rl-validators.constant';
import {EUploadStatus} from '../../../components/upload-file-component/upload.model';
import {IUploadUpdate} from '../../../components/upload-file-component/upload-file.component';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {
    DesignLibraryDetailModel,
    DesignLibraryPatchModel
} from '../../../modules/static-content-rulesets/models/api/design-library.model';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {
    ELibraryJobTypes,
    ICreateLibraryJobData,
    ILibraryJobData,
    IUpdateLibraryJobData
} from '../../../api/services/design-library.api.service';
import {JobModel} from '../../../models/api/job.model';
import {ARApiError, ARLogger} from '@relayter/core';
import {Toaster} from '../../../classes/toaster.class';
import {FileTypeUtil} from '../../../classes/file-type.util';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {EEngineType} from '../../../models/api/template.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import DesignLibraryFormGroup from '../design-library-form-group.interface';
import {EFormStatus} from '../../../app.enums';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {FontModel} from '../../../models/api/font.model';
import {AppConstants} from '../../../app.constants';
import FontsService from '../../../api/services/fonts.service';
import {DesignLibraryApiService} from '../../../api/services/design-library.api.service';

export interface ILibraryFormData {
    library?: DesignLibraryDetailModel;
}

export interface ILibraryFormResult {
    jobId?: string;
}

@Component({
    selector: 'rl-design-library-form-component',
    templateUrl: 'design-library-form.component.html',
    styleUrls: ['design-library-form.component.scss'],
    standalone: false
})
export class DesignLibraryFormComponent implements OnInit {
    public form: FormGroup<DesignLibraryFormGroup>;

    private destroyRef = inject(DestroyRef);
    public modalData: ILibraryFormData = inject(NUC_FULL_MODAL_DATA);
    private fullModalService: FullModalService = inject(FullModalService);
    private designLibraryApiService: DesignLibraryApiService = inject(DesignLibraryApiService);
    private fontsService: FontsService = inject(FontsService);

    public fonts: FontModel[] = [];
    public fontsTotalItems = 0;
    public fontsOffset: number;

    private saveButton: ButtonConfig;

    public fileTypeCategories = [FileTypeUtil.CATEGORIES.INDESIGN_LIBRARY];
    private uploadData: IUploadUpdate;

    public uploadUpdateSubject = new BehaviorSubject<IUploadUpdate>(null);

    public engineTypes = [
        new DropdownItem(EEngineType.INDESIGN, EEngineType.INDESIGN)
    ];

    public updateLibraryJobName = ELibraryJobTypes.UPDATE_INDESIGN_LIBRARY_JOB;
    public createLibraryJobName = ELibraryJobTypes.CREATE_INDESIGN_LIBRARY;

    public ngOnInit(): void {
        this.setupForm();
        this.setupModalActions();
        this.trackStatus();
        this.getFonts()
    }

    private setupForm(): void {
        this.form = new FormGroup({
            name: new FormControl(this.modalData.library?.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            engineType: new FormControl<EEngineType>({value: EEngineType.INDESIGN, disabled: true}, Validators.required),
            fonts: new FormControl(this.modalData.library?.fonts?.map(font => font.getValue()))
        });
    }

    private setupModalActions(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null);
        const saveAction = new FullModalActionModel(this.saveButton);
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.subscribe(() => {
            this.saveButton.loading = true;
            this.modalData.library ? this.updateLibrary() : this.createLibrary();
        });

        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private trackStatus(): void {
        combineLatest([
            this.form.statusChanges.pipe(startWith(this.form.status), distinctUntilChanged(), map((status) => status === EFormStatus.VALID)),
            this.uploadUpdateSubject.pipe(map((uploadUpdate) => {
                this.uploadData = uploadUpdate;
                return uploadUpdate?.status === EUploadStatus.Done || (this.modalData.library && !uploadUpdate);
            }))])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(([formValid, uploadValid]) => this.saveButton.disabled = !formValid || !uploadValid);
    }

    private updateLibrary(): void {
        const jobData = {
            name: this.form.value.name.trim(),
            fonts: this.form.value.fonts || []
        } as any
        if (this.uploadData?.s3Key) {
            jobData.s3Key = this.uploadData.s3Key;
            jobData.libraryId = this.modalData.library._id;
            this.postLibraryJob(this.updateLibraryJobName, jobData as IUpdateLibraryJobData);
        } else {
            this.designLibraryApiService
                .patch(this.modalData.library._id, jobData as DesignLibraryPatchModel)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: () => {
                        this.fullModalService.close({});
                        Toaster.success('Library updated successfully');
                    },
                    error: (error) => this.handleError(error)
                });
        }
    }

    public requestFonts(event?: IDropdownRequestDataEvent): void {
        if (event) {
            this.fonts = [];
            this.fontsTotalItems = 0;
            this.fontsOffset = 0;
        }

        this.getFonts();
    }

    private getFonts(): void {
        this.fontsService
            .find(AppConstants.PAGE_SIZE_MAX, this.fontsOffset)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.fontsTotalItems = result.total;
                    this.fontsTotalItems += result.items.length;

                    this.fonts = this.fonts
                        .filter(item => !result.items.some(newItem => newItem._id === item._id))
                        .concat(result.items);
                },
                error: Toaster.handleApiError
            });
    }

    private createLibrary(): void {
        const jobData = {
            name: this.form.value.name.trim(),
            s3Key: this.uploadData.s3Key,
            fonts: this.form.value.fonts || []
        } as ICreateLibraryJobData;
        this.postLibraryJob(this.createLibraryJobName, jobData);
    }

    private postLibraryJob(jobType: ELibraryJobTypes, jobData: ILibraryJobData): void {
        this.designLibraryApiService
            .postJob(jobType, jobData)
            .pipe(
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: (job: JobModel) => {
                    ARLogger.debug('Library Job scheduled: ' + job._id);
                    this.fullModalService.close({jobId: job._id} as ILibraryFormResult); // close with job id for refreshing
                },
                error: (error) => this.handleError(error)
            });
    }

    private handleError(error: ARApiError): void {
        Toaster.handleApiError(error);
        this.saveButton.loading = false;
    }
}
