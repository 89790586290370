import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EIdentifierSettingFragmentType} from '../../../models/api/workflow-configuration-identifier-setting.model';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {distinctUntilChanged} from 'rxjs/operators';
import {RulePropertyModel} from '../../../models/api/rule-property.model';
import {VariantModel} from '../../../models/api/variant.model';
import {PropertyValueModel} from '../../../models/ui/property-value.model';
import {PropertyControlValidator} from '../../../classes/validators/property-control.validator';
import {FragmentFormGroup} from '../workflow-configuration-form.component';

@Component({
    selector: 'workflow-identifier-setting-fragment-form-component',
    templateUrl: 'workflow-identifier-setting-fragment-form.component.html',
    styleUrls: ['workflow-identifier-setting-fragment-form.component.scss'],
    standalone: false
})
export class WorkflowIdentifierSettingFragmentFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    @Input() public formGroup: FormGroup<FragmentFormGroup>;
    @Input() public properties: RulePropertyModel[];
    @Input() public variants: VariantModel[];
    @Input() public readonly fragmentTypeOptions: DropdownItem<string>[];

    public ngOnInit(): void {
        this.formGroup.controls.type.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((type) => {
                switch (type) {
                    case EIdentifierSettingFragmentType.DERIVED: {
                        this.formGroup.removeControl('fixedValue');
                        if (!this.formGroup.controls.property) {
                            this.formGroup.addControl('property',
                                new FormControl<PropertyValueModel>(null, [Validators.required, PropertyControlValidator()]));
                        }
                        return;
                    }
                    case EIdentifierSettingFragmentType.FIXED: {
                        this.formGroup.removeControl('property');
                        if (!this.formGroup.controls.fixedValue) {
                            this.formGroup.addControl('fixedValue', new FormControl(null, Validators.required));
                        }
                        return;
                    }
                    case EIdentifierSettingFragmentType.RANDOM: {
                        this.formGroup.removeControl('fixedValue');
                        this.formGroup.removeControl('property');
                        return;
                    }
                }
            });
    }
}
