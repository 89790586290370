import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FileTypeUtil} from '../../../../../../../../classes/file-type.util';
import {Toaster} from '../../../../../../../../classes/toaster.class';

@Component({
    selector: 'upload-item-file-input-component',
    templateUrl: './upload-item-file-input.component.html',
    styleUrls: ['./upload-item-file-input.component.scss'],
    standalone: false
})
export class UploadItemFileInputComponent {
    @Input() public fileUploadTitle = 'Files';
    @Input() public dragTitle = 'files';
    @Input() public subtitle: string;
    @Input() public multiple = true;
    @Input() public active: boolean;
    @Input() public required: boolean;
    @Input() public newFileUploaded: boolean;
    @Input() public saveClicked: boolean;
    @Input() public fileTypeCategories: string[];
    @Output() public filesChanged = new EventEmitter<File[]>();

    /**
     * Responds to file(s) drops
     * @param {File[]} files
     */
    public onFileChanged(files: File[]): void {
        let filteredFiles = [...files];
        if (this.fileTypeCategories) {
            filteredFiles = files.filter((file) => {
                const extension = FileTypeUtil.getExtensionFromFileName(file.name);
                return this.fileTypeCategories.includes(extension);
            });
            if (files.length > filteredFiles.length) {
                return Toaster.warn(`Please provide files of the correct types (${this.fileTypeCategories.join(', ')})`);
            }
        }
        if (filteredFiles.length > 0) this.filesChanged.emit(filteredFiles);
    }
}
