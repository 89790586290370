@if (!(PERMISSIONS.GET_PRODUCT_ASSET_EXPORT_SETUPS | userIsAllowedTo)) {
    <nuc-empty-state-component [small]="true"
                               imageUrl="assets/images/empty_states/no_permission_small.svg"
                               [title]="'No permission to view this page. \nPlease contact your administrator.'">
    </nuc-empty-state-component>
} @else if (productAssetExportSetups().length === 0) {
    <nuc-empty-state-component [small]="true"
                               imageUrl="assets/images/empty_states/default.svg"
                               title=" No Product asset export setups available">
    </nuc-empty-state-component>
} @else {
    @if (form(); as form) {
        <div [formGroup]="form" class="container">
            <div resizable
                 resizableId="product-asset-export-form"
                 class="linked-product-assets panel">
                <div class="panel">
                    <h2>Linked product assets</h2>
                    <p>Go to the Assets tab to link additional product assets</p>
                </div>
                <nuc-button-bar class="panel">
                    <nuc-input class="search"
                               [(data)]="searchValue"
                               prefix="nucicon_search"
                               (debounceValueChanged)="onSearch()"
                               placeholder="Search product asset on name">
                    </nuc-input>
                    <nuc-overlay-button icon="nucicon_eye"
                                        [showIndicator]="false"
                                        nucTooltip="View settings"
                                        [content]="propertySettingsComponent">
                    </nuc-overlay-button>
                </nuc-button-bar>

                <div class="grid-view">
                    @for (item of assets(); track item) {
                        <div class="item"
                             cdkDropList
                             [cdkDropListConnectedTo]="[productAssetExport]"
                             [cdkDropListAutoScrollDisabled]="true"
                             [cdkDropListSortingDisabled]="true"
                             (cdkDropListEntered)="enteredAssetItems()"
                             (cdkDropListExited)="exitedAssets($event)">
                            <nuc-media-card-view
                                cdkDrag
                                (cdkDragStarted)="dragStarted()"
                                (cdkDragEnded)="dragEnded()"
                                [cdkDragData]="item"
                                [hoverImage]="false"
                                [hoverCard]="true"
                                [media]="item | collectionViewMediaPipe"
                                size="MEDIUM">
                                <div content>
                                    <div class="selected-container">
                                        <i class="nucicon_image label"></i>
                                        @if (checkIfItemIsSelected(item)) {
                                            <img class="status-icon" src="/assets/images/icon_success.svg"/>
                                        }
                                    </div>
                                    @for (propertySetting of properties(); track propertySetting) {
                                        <div class="field">
                                            <p class="body-strong text-ellipsis">{{ propertySetting.title }}</p>
                                            <p class="text-ellipsis">{{ item | propertySettingDisplay: propertySetting }}</p>
                                        </div>
                                    }
                                </div>
                                <div class="drop-location" *cdkDragPreview>
                                    <img src="{{item.getThumbnailMedia()?.url}}">
                                </div>
                            </nuc-media-card-view>
                            @if (item === transferringAssetItem()) {
                                <nuc-media-card-view [media]="item | collectionViewMediaPipe"
                                                     size="MEDIUM">
                                    <div content>
                                        <div class="selected-container">
                                            <i class="nucicon_image label"></i>
                                            @if (checkIfItemIsSelected(item)) {
                                                <img class="status-icon" src="/assets/images/icon_success.svg"/>
                                            }
                                        </div>
                                        @for (propertySetting of properties(); track propertySetting) {
                                            <div class="field">
                                                <p class="body-strong text-ellipsis">{{ propertySetting.title }}</p>
                                                <p class="text-ellipsis">{{ item | propertySettingDisplay: propertySetting }}</p>
                                            </div>
                                        }
                                    </div>
                                </nuc-media-card-view>
                            }
                        </div>
                    }
                </div>
                @if (!assets()?.length) {
                    <nuc-empty-state-component [small]="true"
                                               [title]="searchValue() ? 'No item matches your search criteria.' : 'This section is empty'"
                                               imageUrl="/assets/images/general_empty_state.svg">
                    </nuc-empty-state-component>
                }
            </div>
            <div class="product-assets-package"
                 formArrayName="assetExports">
                <div class="panel">
                    <h2>Product Assets package</h2>
                    <p>Add assets from the Linked Product Assets section to include in this package export</p>
                </div>
                <nuc-dropdown placeholder="Select product asset export setup"
                              class="panel"
                              [nullOption]="false"
                              [data]="selectedProductAssetExportSetupId()"
                              (dataChange)="onProductAssetExportSetupChanged($event)"
                              [items]="productAssetExportSetups()"/>

                <div [formGroup]="selectedProductAssetExportSetupControl()" cdkDropList #productAssetExport=cdkDropList
                     class="grid-view drop-area" [cdkDropListAutoScrollDisabled]="true"
                     [cdkDropListSortingDisabled]="true"
                     (cdkDropListDropped)="onDrop($event)">
                    @for (productSortItem of selectedProductAssetExportSetupControl()?.controls.items.value; track productSortItem; let index = $index) {
                        <div class="drop-target" [class.dragging]="dragging()">
                            <div class="drop-location" [class.dragging]="dragging()"
                                 [class.selected]="productSortItem.asset" cdkDrag
                                 [cdkDragData]="productSortItem.asset"
                                 [cdkDragDisabled]="!productSortItem.asset"
                                 (cdkDragStarted)="dragStarted()"
                                 (cdkDragEnded)="dragEnded()">
                                @if (productSortItem.asset; as asset) {
                                    <img src="{{asset.getThumbnailMedia()?.url}}">
                                } @else {
                                    <i class="drop-img" [class.dragging]="dragging()"></i>
                                    <p class="margin-bot-base">Drag an asset here</p>
                                }
                            </div>
                            <div class="label-container">
                                <div class="label text-ellipsis"
                                     nucTooltip="#{{index + 1}} {{productSortItem.sortItem.label ?? ''}}">
                                    <span class="body-strong index">#{{ index + 1 }}</span>
                                    <span>{{ productSortItem.sortItem.label ?? '' }}</span>
                                </div>
                                @if (productSortItem.asset) {
                                    <nuc-button-secondary class="delete-btn" icon="nucicon_trash_fill"
                                                          (click)="removeProductAsset(index)"
                                                          nucTooltip="Remove asset from product package export"/>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    }

    <ng-template #propertySettingsComponent>
        <rl-property-settings-component [context]="EPropertySettingsContext.ASSET"></rl-property-settings-component>
    </ng-template>
}
