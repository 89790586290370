import {Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {StickyNoteModel} from '../../../models/api/sticky-note.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Toaster} from '../../../classes/toaster.class';
import {StickyNoteCommentModel} from '../../../models/api/sticky-note-comment.model';
import {ARLogger, ARPagedResponseDataModel} from '@relayter/core';
import {WorkflowConfigurationActionModel} from '../../../models/api/workflow-configuration-action.model';
import {Observable, of, Subscription} from 'rxjs';
import {PublicationsService} from '../../../api/services/publications.service';
import {ActivatedRoute} from '@angular/router';
import {RLDatePipe} from '../../../pipes/rl-date.pipe';
import {WorkflowConfigurationComponentModel} from '../../../models/api/workflow-configuration-component.model';
import {EmptyValuePipe} from '../../../pipes/empty-value.pipe';
import {MultipleFileInputComponent} from '../../inputs/multiple-file-input/multiple-file-input.component';
import {AmazonService} from '../../../api/services/amazon.service';
import {IRichInputData, IUser, NucDialogService, EDataTypes} from '@relayter/rubber-duck';
import {FileTypeUtil} from '../../../classes/file-type.util';
import {UsersApiService} from '../../../api/services/users.api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';
import {UserService} from '../../../api/services/users.service';
import {UserDetailModel} from '../../../models/api/user.model';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {AppConstants} from '../../../app.constants';

interface ICommentForm {
    comment: FormControl<IRichInputData>;
    files: FormControl<string[]>;
}
@Component({
    selector: ' rl-sticky-comment',
    templateUrl: './sticky-comment.component.html',
    styleUrls: ['./sticky-comment.component.scss'],
    standalone: false
})
export class StickyCommentComponent extends MultipleFileInputComponent implements OnChanges, OnInit {

    public readonly DELETED_USER = EmptyValuePipe.defaultValues.DELETED_USER;

    private userIsAllowedToPipe: UserIsAllowedToPipe = inject(UserIsAllowedToPipe);

    @Input() public stickyNote: StickyNoteModel;
    @Input() public addCommentAction: WorkflowConfigurationActionModel;
    @Input() public showCommentsAction: WorkflowConfigurationActionModel;
    @Input() public publicationId: string;
    @Input() public component: WorkflowConfigurationComponentModel;

    public isDragging: boolean = false;

    @Output() public commentAdded: EventEmitter<void> = new EventEmitter<void>();

    public dateFormats = RLDatePipe.dateFormats;
    public comments: StickyNoteCommentModel[];
    public commentsLoading: boolean = false;

    public loading = false;
    public formGroup: FormGroup<ICommentForm> = new FormGroup({
        comment: new FormControl(null, Validators.required),
        files: new FormControl([])
    });

    private page: number = 0;
    private pageSize: number = 10;
    public totalComments: number = 0;

    private commentsSubscription: Subscription;
    private uploadedFiles: string[]; // TODO create file model for this

    public getUserSuggestions = (query: string): Observable<IUser[]> => {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_USERS)){
            return this.usersApiService.getUsers(null, null, 6, null, null, query)
                .pipe(
                    map((results) => results.items.map(item => {
                        return {
                            _id: item._id,
                            name: item.fullName,
                            email: item.email
                        } as IUser
                    })),
                    takeUntilDestroyed(this.destroyRef)
                );
        } else {
            return of([]);
        }
    }
    public loggedInUser: UserDetailModel;

    constructor(private route: ActivatedRoute,
                private publicationsService: PublicationsService,
                private usersApiService: UsersApiService,
                private userService: UserService,
                amazon: AmazonService,
                dialogService: NucDialogService) {
        super(amazon, dialogService);
        this.publicationId = this.route.snapshot.params['publication_id'];
    }

    public ngOnInit() {
        super.ngOnInit();

        this.loggedInUser = this.userService.getLoggedInUser();

        // configure supported files for upload
        this.fileTypeCategories = FileTypeUtil.DOWNLOAD_CATEGORIES;

        this.uploadedS3Keys.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((s3Keys) => {
            this.uploadedFiles = [...s3Keys];
        });
    }

    /**
     * Lifecycle method called on changes
     * If sticky note is set / updated call refreshComments to get the comments for the sticky note
     * @param {SimpleChanges} changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.stickyNote) {
            this.refreshComments();
            this.formGroup.reset();
        }
    }

    /**
     * Resets the comments, page index and total comments
     * Then calls getComments()
     */
    private refreshComments(): void {
        if (this.commentsSubscription) {
            this.commentsSubscription.unsubscribe();
            this.commentsSubscription = null;
        }
        this.uploadedFiles = [];
        this.uploadingFiles = [];
        this.page = 0;
        this.comments = null;
        this.totalComments = 0;
        this.getComments();
    }

    /**
     * Gets the comments for the sticky note from the API
     */
    private getComments(): void {
        if (!this.showCommentsAction?.from.includes(this.stickyNote.status)) return;

        this.commentsLoading = true;

        this.commentsSubscription = this.publicationsService
            .getCommentsForStickyNote(this.publicationId, this.stickyNote._id, this.component._id, this.pageSize, this.page * this.pageSize)
            .subscribe({
                next: (result: ARPagedResponseDataModel<StickyNoteCommentModel>) => {
                    if (this.comments) {
                        this.comments = this.comments.concat(result.items);
                    } else {
                        this.comments = result.items;
                    }
                    this.commentsLoading = false;
                    this.totalComments = result.total;
                },
                error: error => {
                    Toaster.handleApiError(error);
                    this.commentsLoading = false;
                    this.comments = null;
                    this.totalComments = 0;
                }
            });

    }

    /**
     * Responds to submit button clicks
     */
    public onSubmit(): void {
        this.loading = true;
        const mentionData: IRichInputData = this.formGroup.controls.comment.value;
        const body = {
            message: mentionData.rawText,
            htmlMessage: mentionData.htmlString,
            mentions: [...new Set<string>(mentionData.richData.filter(data => data.type === EDataTypes.MENTION).map(data =>
                (data.value as IUser)._id))],
            files: this.uploadedFiles
        };
        this.publicationsService
            .postCommentOnSticky(this.publicationId, this.stickyNote._id, this.component._id,
                body)
            .subscribe({
                next: (result: StickyNoteCommentModel) => {
                    Toaster.success('Comment posted');
                    ARLogger.debug('Comment posted: ' + result.message);
                    this.formGroup.reset();
                    this.loading = false;
                    this.refreshComments();
                    this.uploadedFiles = [];
                    this.uploadingFiles = [];
                    this.commentAdded.emit();
                },
                error: error => {
                    Toaster.handleApiError(error);
                    this.loading = false;
                }
            });
    }

    /**
     * Responds to show more button clicks
     * Increments the page index and calls getComments()
     */
    public onShowMoreButtonClicked(): void {
        this.page += 1;
        this.getComments();
    }

    public onFilesChanged(event): void {
        this.isDragging = false;
        let files: File[] = [];
        if (event.target.files && event.target.files.length > 0) {
            files = Array.from(event.target.files);
        }
        event.target.value = '';

        super.onFilesChanged(files);
    }

    public downloadFile(fileUrl: string): void {
        window.open(fileUrl);
    }
}
