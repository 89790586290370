import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {WorkflowConfigurationComponentModel} from '../../../../../../models/api/workflow-configuration-component.model';
import {WorkflowConfigurationStepModel} from '../../../../../../models/api/workflow-configuration-step.model';
import {WorkflowConfigurationModel} from '../../../../../../models/api/workflow-configuration.model';
import {PublicationModel} from '../../../../../../models/api/publication.model';
import {
    WorkflowConfigurationActionModel,
    EDownloadActionName,
    ETransitionTriggerActionName
} from '../../../../../../models/api/workflow-configuration-action.model';
import {UserIsAllowedToPipe} from '../../../../../../pipes/user-is-allowed-to.pipe';
import {Toaster} from '../../../../../../classes/toaster.class';
import {ARLogger} from '@relayter/core';
import {EPublicationJobType, IDownloadPackageJobData, PublicationsService} from '../../../../../../api/services/publications.service';
import {
    CustomWorkflowFilesDownloadComponent,
    IFilesDownloadModalData
} from './custom-workflow-files-download/custom-workflow-files-download.component';
import {FullModalConfig, FullModalService} from '@relayter/rubber-duck';
import {CustomWorkflowFilesUploadComponent, IFilesUploadModalData} from './custom-workflow-upload/custom-workflow-files-upload.component';
import {Subject, Subscription} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {WorkflowConfigurationFilterModel} from '../../../../../../models/api/workflow-configuration-filter.model';
import {WorkflowConfigurationFilterOptionModel} from '../../../../../../models/api/workflow-configuration-filter-option.model';
import {VariantModel} from '../../../../../../models/api/variant.model';
import {CustomWorkflowService} from '../custom-workflow.service';
import {EPackageType} from './custom-workflow-files-download/package-type/files-download-package-type.component';
import {RLCountsModel} from '../../../../../../api/response/rl-counts.model';

@Component({
    selector: 'rl-custom-workflow-files-component',
    templateUrl: './custom-workflow-files.component.html',
    styleUrls: ['./custom-workflow-files.component.scss'],
    standalone: false
})
export class CustomWorkflowFilesComponent implements OnInit, OnDestroy, OnChanges {

    @Input() public publication: PublicationModel;
    @Input() public workflowConfiguration: WorkflowConfigurationModel;
    @Input() public component: WorkflowConfigurationComponentModel;
    @Input() public step: WorkflowConfigurationStepModel;
    @Input() public activeFilters: Map<WorkflowConfigurationFilterModel, WorkflowConfigurationFilterOptionModel[]>;
    @Input() public counts: RLCountsModel;

    public actions: WorkflowConfigurationActionModel[];
    public downloadEnabled: boolean;
    public uploadEnabled: boolean;

    public total: number;
    public subscription: Subscription;
    private onDestroySubject = new Subject<void>();
    public activeVariant: VariantModel;
    private publicationVariants: VariantModel[];

    constructor(protected publicationsService: PublicationsService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private customWorkflowService: CustomWorkflowService,
                private fullModalService: FullModalService) {
    }

    public ngOnInit(): void {
        this.actions = this.component.actions.filter((action) => this.userIsAllowedToPipe.transform(action.permissions));
        this.downloadEnabled = this.actions.some((action) => Object.values(EDownloadActionName).includes(action.name as EDownloadActionName));
        this.uploadEnabled = this.actions.some((action) => action.name === ETransitionTriggerActionName.UPLOAD_ITEM_FILES);

        this.customWorkflowService.activeVariant$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(variant => this.activeVariant = variant);

        this.customWorkflowService.publicationVariants$
            .pipe(take(1), takeUntil(this.onDestroySubject))
            .subscribe(publicationVariants => this.publicationVariants = publicationVariants);
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.counts) this.getPublicationItemCount();
    }

    /**
     * Gets the total publication items for the current step from the counts
     */
    public getPublicationItemCount(): void {
        this.total = this.counts?.steps.find(step => step._id === this.step._id)?.count ?? 0;
    }

    public openDownloadModal(): void {
        const modalConfig = new FullModalConfig(
            'Download publication items',
            'Choose the package type and items you want to download.',
            {
                actions: this.actions,
                activeFilters: this.activeFilters,
                publication: this.publication,
                step: this.step,
                variant: this.activeVariant
            } as IFilesDownloadModalData);
        this.fullModalService.open(CustomWorkflowFilesDownloadComponent, modalConfig).afterClosed().subscribe(result => {
            if (result) this.onDownloadButtonClicked(result);
        });
    }

    public onDownloadButtonClicked(jobData: IDownloadPackageJobData): void {
        jobData.step = this.step._id;
        jobData.publicationId = this.publication._id;
        jobData.variantId = this.activeVariant?._id;

        if (jobData.packageType === EPackageType.MERGED_PDF_IN_LAYOUT) {
            if (!this.workflowConfiguration.layout) {
                Toaster.error(`No layout configured in the workflow. Cannot download: ${EPackageType.MERGED_PDF_IN_LAYOUT}`);
                return;
            }
            jobData['layoutId'] = this.workflowConfiguration.layout._id;
        }

        this.publicationsService.postJob(EPublicationJobType.PACKAGE_GENERATION, jobData)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result) => ARLogger.debug('Job scheduled: ' + result._id),
                error: Toaster.handleApiError
            });
    }

    public openUploadModal(): void {
        const modalConfig = new FullModalConfig(
            'Upload Publication Files',
            'Add or update a publication item by uploading the design.',
            {
                publication: this.publication,
                step: this.step,
                actions: this.actions,
                publicationVariants: this.publicationVariants
            } as IFilesUploadModalData);
        this.fullModalService.open(CustomWorkflowFilesUploadComponent, modalConfig);
    }
}
