import {Component, Input} from '@angular/core';
import {WorkflowConfigurationComponentModel} from '../../../../../../models/api/workflow-configuration-component.model';

@Component({
    selector: 'rl-custom-workflow-placeholder',
    templateUrl: './custom-workflow-placeholder.component.html',
    styleUrls: ['./custom-workflow-placeholder.component.scss'],
    standalone: false
})
export class CustomWorkflowPlaceholderComponent {
    @Input() public component: WorkflowConfigurationComponentModel;
}
