import {Component, Inject, Optional} from '@angular/core';
import {PublicationsService} from '../../../../../../api/services/publications.service';
import {UserIsAllowedToPipe} from '../../../../../../pipes/user-is-allowed-to.pipe';
import {
    FullModalService,
    NUC_FULL_MODAL_DATA,
    NucDialogService} from '@relayter/rubber-duck';
import {CustomWorkflowService} from '../custom-workflow.service';
import {TabBarItemModel} from '../../../../../../models/ui/tab-bar-item.model';
import {IWorkflowModalData} from '../../../../../../models/interfaces/workflow-modal-data.interface';

@Component({
    selector: 'rl-custom-workflow-overview-component',
    templateUrl: './custom-workflow-overview.component.html',
    styleUrls: ['./custom-workflow-overview.component.scss'],
    standalone: false
})
export class CustomWorkflowOverviewComponent {
    public TAB_ITEMS = 0;
    public TAB_DELETED_ITEMS = 1;

    public sliderValue = 1; // medium size
    public allItemsTab = new TabBarItemModel('All items', this.TAB_ITEMS);
    public deletedItemsTab = new TabBarItemModel('Deleted items', this.TAB_DELETED_ITEMS);
    public tabBarItems: TabBarItemModel[] = [
        this.allItemsTab,
        this.deletedItemsTab];
    private _selectedTab = this.tabBarItems[this.TAB_ITEMS];
    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabBarItems.find((t) => t.title === tab.title).index;
            this._selectedTab = tab;
            this._selectedTab.index = index;
        }
    }

    constructor(protected userIsAllowedToPipe: UserIsAllowedToPipe,
                protected customWorkflowService: CustomWorkflowService,
                protected publicationsService: PublicationsService,
                protected dialogService: NucDialogService,
                protected fullModalService: FullModalService,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowModalData) {
    }
}
