<div class="mask">
    <custom-workflow-preview-sticky-list-view
        [publication]="publication"
        [workflowConfiguration]="workflowConfiguration"
        [step]="step"
        [component]="component"
        [actions]="actions"
        [activeFilters]="activeFilters"
        [campaignItemDataFields]="campaignItemDataFields">
    </custom-workflow-preview-sticky-list-view>

    <div class="details-container" *ngIf="selectedStickyNote" [@slideAnimation]>
        <custom-workflow-sticky-note-details-component
            [loading]="loading"
            [component]="component"
            [publicationId]="publication._id"
            [campaignId]="campaignId"
            [actions]="actions"
            [campaignItemDataFields]="campaignItemDataFields">
        </custom-workflow-sticky-note-details-component>
    </div>
</div>
