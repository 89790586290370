import {autoserialize, autoserializeAs} from 'cerialize';
import {VariantModel} from './variant.model';

export class ProjectModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public tags: string[];
    @autoserialize public status: string;
    @autoserializeAs(VariantModel) public variants: VariantModel[];
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;
}
