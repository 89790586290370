<nuc-tab-bar [tabs]="tabBarItems"
             [disabledTabs]="disabledTabs"
             [(activeTab)]="selectedTab">
</nuc-tab-bar>
<hr class="full-width-tab-hr"/>

<div class="content">
    @if (variants) {
        @if (selectedTab) {
            <form [formGroup]="form">
                @switch (selectedTab.index) {
                    @case (TAB_PRODUCT) {
                        <data-fields-information-form
                            [form]="form"
                            [dataFields]="dataFields"
                            [formConfig]="formConfig"
                            [variants]="variants" />
                    }
                    @case (TAB_ASSETS) {
                        <product-assets-form [form]="form"
                                             [assets]="selectedAssets" />
                    }
                    @case (TAB_ASSET_EXPORT) {
                        <product-asset-export-form [form]="form"
                                                   [selectedAssets]="selectedAssets"
                                                   [productAssetExportSetups]="productAssetExportSetups"
                                                   [productAssetExports]="productAssetExports"
                                                   [(selectedProductAssetExportSetupId)]="selectedProductAssetExportSetupId" />
                    }
                }
            </form>
        }
    } @else {
        <rl-loading-indicator></rl-loading-indicator>
    }
</div>
