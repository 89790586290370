<div class="form-container" *ngIf="formConfig else otherState">
    <div class="variant-row" *ngIf="variantEnabled">
        <nuc-label icon="nucicon_variant" iconColorClass="variant-highlight">Variant</nuc-label>
        <div class="variant-selection">
            <p>Show the variants data fields in: </p>
            <nuc-dropdown class="variant"
                          placeholder="Select variant"
                          [nullOption]=false
                          [formControl]="variantFormControl"
                          [items]="variants">
            </nuc-dropdown>
        </div>
        <hr>
    </div>

    <div class="form-row" *ngFor="let row of formConfig.rows">
        <rl-data-field-input *ngFor="let field of row.fields"
                             [selectedVariant]="selectedVariant"
                             [readonly]="readonly"
                             [dataField]="field.dataField"
                             [options]="field.options"
                             [controlName]="variantFormControl.value && field.dataField.enableVariants
                                ? field.dataField.fieldName +  '.' + variantFormControl.value
                                : field.dataField.fieldName"
                             [form]="dataFieldsGroup">
        </rl-data-field-input>
    </div>
</div>

<ng-template #otherState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission.svg"
                               title="No form"
                               subtitle="No form has not been set up, contact your administrator">
    </nuc-empty-state-component>
</ng-template>
