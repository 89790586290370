import {Component, DestroyRef, inject, Inject, OnInit, Optional} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {Toaster} from '../../classes/toaster.class';
import {TabBarItemModel} from '../../models/ui/tab-bar-item.model';
import {AssetModel, AssetPutModel} from '../../models/api/asset.model';
import {distinctUntilChanged, finalize, map, switchMap} from 'rxjs/operators';
import {IUploadUpdate} from '../../components/upload-file-component/upload-file.component';
import {forkJoin, of, Subscription} from 'rxjs';
import {EUploadStatus} from '../../components/upload-file-component/upload.model';
import {DataFieldsComponentUtil} from '../../classes/data-fields-component.util';
import {AssetProductModel} from '../../models/api/asset-product.model';
import {VariantService} from '../../api/services/variant.service';
import {VariantModel} from '../../models/api/variant.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormService} from '../../api/services/form.service';
import {EFormContext, FormDetailModel} from '../../models/api/form.model';
import {EFormStatus} from '../../app.enums';
import {AssetApiService} from '../../api/services/asset.api.service';

export enum ETabFormGroup {
    ASSET_EDIT_INFORMATION = 'dataFields',
    ASSET_EDIT_ASSET = 'asset'
}

export interface IAssetEditFormData {
    assetId: string;
}

@Component({
    selector: 'asset-edit-form',
    templateUrl: './asset-edit-form.component.html',
    styleUrls: ['./asset-edit-form.component.scss'],
    standalone: false
})
export class AssetEditFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);

    public TAB_INFORMATION = 0;
    public TAB_ASSETS = 1;

    public informationTab = new TabBarItemModel('Information', this.TAB_INFORMATION);
    public assetsTab = new TabBarItemModel('Asset', this.TAB_ASSETS);
    public tabBarItems: TabBarItemModel[] = [
        this.informationTab,
        this.assetsTab];
    private _selectedTab = this.tabBarItems[this.TAB_INFORMATION];
    public variants: VariantModel[];

    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabBarItems.find((t) => t.title === tab.title).index;
            this._selectedTab = tab;
            this._selectedTab.index = index;
            this.setConfirmButton();
            this.updateNextButtonState();
        }
    }

    private nextTabAction(): void {
        this.selectedTab = this.tabBarItems.find((tab) => tab.index === this.selectedTab.index + 1);
    }

    /**
     * Responder to modal confirm button click. Initialized with 'go to next tab'
     */
    private onConfirmClicked = this.nextTabAction;

    public form: UntypedFormGroup = new UntypedFormGroup({});
    public formConfig: FormDetailModel;

    private cancelAction: FullModalActionModel;
    private confirmAction: FullModalActionModel;
    private actions: FullModalActionModel[] = [];

    private confirmButton: ButtonConfig;

    public assetDetails: AssetProductModel;
    public asset: AssetModel;
    public dataFields: Record<string, any>;

    public uploadUpdate: IUploadUpdate;
    private dataSubscription: Subscription;

    constructor(private fullModalService: FullModalService,
                private variantService: VariantService,
                private assetService: AssetApiService,
                private formService: FormService,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) private modalData: IAssetEditFormData) {
    }

    public ngOnInit(): void {
        this.dataSubscription = forkJoin({
            variantsData: this.variantService.getVariants(),
            assetDetails: this.assetService.getAsset(this.modalData.assetId),
            formConfig: this.formService.getForms(EFormContext.ASSET, 1)
                .pipe(
                    map((result) => result.items[0]),
                    switchMap((form) =>
                        form ? this.formService.getForm(form._id) : of(null))
                )
        }).pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(({variantsData, assetDetails, formConfig}) => {
            this.variants = variantsData.items;
            this.assetDetails = assetDetails;
            this.asset = assetDetails.asset;
            this.dataFields = this.asset.dataFields || {};
            this.formConfig = formConfig;
        });

        this.form.statusChanges.pipe(
            distinctUntilChanged(),
            map((status) => status === EFormStatus.VALID),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(() => {
            this.updateNextButtonState();
        });

        this.initButtons();
    }

    private initButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.confirmButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Next', null, null, true);

        this.cancelAction = new FullModalActionModel(cancelButton);
        this.confirmAction = new FullModalActionModel(this.confirmButton);

        this.cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        this.confirmAction.observable.subscribe(() => this.onConfirmClicked());

        this.actions = [this.cancelAction, this.confirmAction];
        this.fullModalService.setModalActions(this.actions);
    }

    /**
     * If current tab is information or asset, text is Next and action is 'go to next tab'
     * Otherwise text is 'Save' and action is 'Save'
     */
    private setConfirmButton(): void {
        switch (this._selectedTab.index) {
            case this.TAB_INFORMATION:
                this.confirmAction.button.text = 'Next';
                this.onConfirmClicked = this.nextTabAction;
                return;
            case this.TAB_ASSETS:
                this.confirmAction.button.text = 'Save';
                this.onConfirmClicked = this.onSaveButtonClicked;
                break;
        }
    }

    private onSaveButtonClicked(): void {
        // no asset or asset is done
        if (this.form.valid && (!this.uploadUpdate || this.uploadUpdate.status === EUploadStatus.Done)) {
            this.updateAsset();
        }
    }

    private updateAsset(): void {
        this.confirmButton.loading = true;
        const dataFields = this.form.get(ETabFormGroup.ASSET_EDIT_INFORMATION)
            ? DataFieldsComponentUtil.getBodyForDataFields(this.form.get(ETabFormGroup.ASSET_EDIT_INFORMATION).value, true)
            : this.asset.dataFields;
        const s3Key = this.form.get(ETabFormGroup.ASSET_EDIT_ASSET)?.value.uploadedAsset?.s3Key;
        const assetBody = new AssetPutModel(dataFields, s3Key);

        this.assetService.putAsset(this.asset._id, assetBody)
            .pipe(
                finalize(() => this.confirmButton.loading = false),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: (result) => {
                    Toaster.success('Asset updated successfully');
                    this.fullModalService.close(result);
                },
                error: (error) => {
                    Toaster.handleApiError(error);
                    this.confirmButton.loading = false;
                }
            });
    }

    public updateState(uploadUpdate: IUploadUpdate): void {
        this.uploadUpdate = uploadUpdate;

        this.updateTabsState();
        this.updateNextButtonState();
    }

    public updateTabsState(): void {
        const disabled = this.uploadUpdate && this.uploadUpdate.status !== EUploadStatus.Done;
        this.tabBarItems.forEach(tabBar => tabBar.disabled = disabled);
    }

    public updateNextButtonState(): void {
        this.confirmButton.disabled = !this.dataSubscription?.closed ||
            (this._selectedTab.index === this.TAB_ASSETS &&
                (!this.form.valid || (this.uploadUpdate && this.uploadUpdate.status !== EUploadStatus.Done)));
    }

}
