import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BUTTON_TYPE, ButtonConfig, DropdownComponent, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {UserIsAllowedToPipe} from '../../pipes/user-is-allowed-to.pipe';
import {ARLogger} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {CampaignService, ECampaignJobTypes, ICampaignPackageExportJobData} from '../../api/services/campaigns.service';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {DataCollectionService} from '../../api/services/data-collection.service';
import {EDataCollectionName, EFormStatus} from '../../app.enums';

export interface IPackageExportFormComponentData {
    campaignId: string;
    campaignPackageIds?: string[];
}

export enum EPackageType {
    FULL = 'FULL',
    DATA = 'DATA',
    ASSETS = 'ASSETS',
    PDF_EXPORT = 'PDF_EXPORT'
}

export enum EDataExportFormat {
    XML = 'XML',
    CSV = 'CSV',
    EXCEL = 'EXCEL'
}

export enum EAssetExportFormat {
    MERGED_PDF = 'MERGED_PDF'
}

@Component({
    selector: 'package-export-form',
    templateUrl: './package-export-form.component.html',
    styleUrls: ['./package-export-form.component.scss'],
    standalone: false
})
export class PackageExportFormComponent implements OnInit, OnDestroy {
    private readonly campaignId: string;
    private exportButton: ButtonConfig;

    public packageTypes: DropdownItem<EPackageType>[] = [
        new DropdownItem('Full package', EPackageType.FULL),
        new DropdownItem('Data package', EPackageType.DATA),
        new DropdownItem('Design material package', EPackageType.ASSETS),
        new DropdownItem('Package PDF Export', EPackageType.PDF_EXPORT),
    ];

    public dataExportFormats: DropdownItem<EDataExportFormat>[] = [
        new DropdownItem('XML', EDataExportFormat.XML),
        new DropdownItem('CSV', EDataExportFormat.CSV),
        new DropdownItem('EXCEL', EDataExportFormat.EXCEL)
    ];

    public assetExportFormats: DropdownItem<EAssetExportFormat>[] = [
        new DropdownItem('Merged PDF', EAssetExportFormat.MERGED_PDF)
    ];

    public publicationVariants: DropdownItem<string>[] = [];
    public totalPublicationVariants = 0;
    public publicationVariantControl: FormControl;

    public showDataExportFormat = false;
    public showAssetExportFormat = false;

    public formGroup: FormGroup;
    private onDestroySubject = new Subject<void>();
    private subscription: Subscription;

    constructor(@Inject(NUC_FULL_MODAL_DATA) private modalData: IPackageExportFormComponentData,
                private fullModalService: FullModalService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private campaignService: CampaignService,
                private dataCollectionService: DataCollectionService) {
        this.campaignId = modalData.campaignId;
    }

    public ngOnInit(): void {
        this.getPublicationVariants();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public getPublicationVariants(offset = 0): void {
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = this.dataCollectionService.getDataCollectionValues(EDataCollectionName.PUBLICATION,
            'variants.name', {campaign: this.campaignId}, DropdownComponent.DEFAULT_LIMIT, offset, null, 'variants._id')
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(result => {
                this.totalPublicationVariants = result.total;
                this.publicationVariants = this.publicationVariants.concat(result.items.map(item => {
                    return new DropdownItem<string>(item.title, item._id);
                }));

                if (!this.formGroup) {
                    this.initForm();
                    this.setupModal();
                }

                if (this.publicationVariants.length && !this.publicationVariantControl.value) { // initialize the value
                    this.fullModalService.setDescription('Select the variant, package type and export format of the items you wish to export.');
                    this.publicationVariantControl.patchValue(this.publicationVariants[0]);
                }
            });

    }

    private setupModal(): void {
        this.exportButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Export', null, null, true);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new FullModalActionModel(cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close(false));
        const save = new FullModalActionModel(this.exportButton);
        save.observable.subscribe(() => {
            this.exportButton.loading = true;
            this.exportButton.disabled = true;
            this.postJob();
        });
        this.fullModalService.setModalActions([cancel, save]);
    }

    private initForm(): void {
        this.formGroup = new FormGroup({
            packageType: new FormControl(null, Validators.required),
            dataExportFormat: new FormControl(),
            assetExportFormat: new FormControl()
        });

        if (this.publicationVariants.length) {
            this.publicationVariantControl = new FormControl(null, Validators.required);
            this.formGroup.addControl('variant', this.publicationVariantControl);
        }

        // exportFormat is required when packageType is not ASSETS
        this.formGroup.get('packageType').valueChanges
            .pipe(
                takeUntil(this.onDestroySubject)
            )
            .subscribe((value) => {
                if ([EPackageType.FULL, EPackageType.DATA].includes(value)) {
                    this.formGroup.get('dataExportFormat').setValidators([Validators.required]);
                } else {
                    this.formGroup.get('dataExportFormat').patchValue(null);
                    this.formGroup.get('dataExportFormat').clearValidators();
                }

                this.formGroup.get('dataExportFormat').updateValueAndValidity();
            });

        this.formGroup.statusChanges
            .pipe(
                distinctUntilChanged(),
                map((status) => status === EFormStatus.VALID),
                takeUntil(this.onDestroySubject)
            )
            .subscribe((isValid: boolean) => this.exportButton.disabled = !isValid);
    }

    private postJob(): void {
        const jobData: ICampaignPackageExportJobData = {
            campaignId: this.campaignId,
            packageType: this.formGroup.get('packageType').value,
            dataExportFormat: this.formGroup.get('dataExportFormat')?.value,
            assetExportFormat: this.formGroup.get('assetExportFormat')?.value,
            campaignPackageIds: this.modalData.campaignPackageIds
        };

        if (this.formGroup.get('variant')?.value) {
            jobData.variantId = this.formGroup.get('variant').value;
        }

        this.campaignService.postJob(ECampaignJobTypes.CAMPAIGN_PACKAGE_EXPORT_JOB, jobData)
            .subscribe({
                next: (job) => {
                    this.fullModalService.close(job._id); // close with job id
                    ARLogger.debug('Campaign package export job scheduled: ' + job._id);
                }, error: (error) => {
                    Toaster.handleApiError(error);
                    this.exportButton.disabled = false;
                    this.exportButton.loading = false;
                }
            });
    }

    /**
     * Sets the selection options of asset and data format based on package type
     * @param packageType
     */
    public packageTypeSelectionChanged(packageType: EPackageType) {
        this.showDataExportFormat = packageType &&
            [EPackageType.FULL, EPackageType.DATA].includes(packageType);
        this.showAssetExportFormat = packageType &&
            [EPackageType.FULL, EPackageType.ASSETS, EPackageType.PDF_EXPORT].includes(packageType);
    }
}
