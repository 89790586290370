import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {RulePropertyModel} from '../../../models/api/rule-property.model';
import {
    EWorkflowConfigurationActionOptionNotificationReceiver,
    EWorkflowConfigurationActionOptionNotificationType
} from '../../../models/api/workflow-configuration-option.model';
import {EStickyNoteStatus} from '../../../app.enums';
import {UserModel} from '../../../models/api/user.model';
import {UsersApiService} from '../../../api/services/users.api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../classes/toaster.class';
import {FormArrayMinLengthValidator} from '../../../classes/validators/form-array-min-length.validator';
import {FormControlArrayMinLengthValidator} from '../../../classes/validators/form-control-array-min-length.validator';

@Component({
    selector: 'workflow-configuration-action-notification-form',
    templateUrl: 'workflow-configuration-action-notification-form.component.html',
    styleUrls: ['workflow-configuration-action-notification-form.component.scss'],
    standalone: false
})
export class WorkflowConfigurationActionNotificationFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    @Input() public formGroup: FormGroup;
    @Input() public initialValue: any;
    @Input() public properties: RulePropertyModel[];
    @Input() public noteToStatus: DropdownItem<EStickyNoteStatus>[];
    @Input() public campaignItemFieldOptions: DropdownItem<string>[];

    public loadingForm = true;
    public readonly notificationTypes = Object.values(EWorkflowConfigurationActionOptionNotificationType)
        .map((value) => new DropdownItem<EWorkflowConfigurationActionOptionNotificationType>(value, value));
    public readonly receiverTypes = Object.values(EWorkflowConfigurationActionOptionNotificationReceiver)
        .map((value) => new DropdownItem<EWorkflowConfigurationActionOptionNotificationReceiver>(value, value));
    public users: UserModel[];

    constructor(private usersApiService: UsersApiService) {}

    public ngOnInit(): void {
        this.usersApiService.getAllUsers()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.users = result;
                    this.setupForm();
                },
                error: Toaster.handleApiError
            });
    }

    public setupForm(): void {
        this.formGroup.addControl('campaignItemDataFields', new FormControl(this.initialValue?.campaignItemDataFields));

        this.formGroup.addControl('receivers', new FormArray(this.initialValue?.receivers?.map((receiver) => {
                const formGroup = new UntypedFormGroup({
                    type: new FormControl(receiver.type)
                });
                if (receiver.type === EWorkflowConfigurationActionOptionNotificationReceiver.NOTE_CHANGELOG) {
                    formGroup.addControl('status', new FormControl(receiver.value));
                }
                if (receiver.type === EWorkflowConfigurationActionOptionNotificationReceiver.USER_IDS) {
                    formGroup.addControl('users', new FormControl(receiver.value, FormControlArrayMinLengthValidator(1)));
                }
                return formGroup;
            }) || [], [this.noDuplicateValue('type', EWorkflowConfigurationActionOptionNotificationReceiver.NOTE_CHANGELOG),
                this.noDuplicateValue('status'), FormArrayMinLengthValidator(1)])
        );
        this.loadingForm = false;
    }

    public deleteReceiver(index: number): void {
        const formArray = this.formGroup.controls.receivers as FormArray;
        formArray.removeAt(index);
    }

    public addReceiver(): void {
        const formArray = this.formGroup.controls.receivers as FormArray;
        formArray.push(new FormGroup({
            type: new FormControl(null, Validators.required)
        }));
    }

    private noDuplicateValue(controlName: string, exceptionValue?: EWorkflowConfigurationActionOptionNotificationReceiver): ValidatorFn {
        return (formArray: FormArray): null => {
            const subFormGroups = formArray.controls;

            const duplicateValues = subFormGroups
                .map((item) => item.value[controlName])
                .filter((value) => value !== exceptionValue)
                .filter((element, index, array) => {
                    return !!element && array.indexOf(element) !== index;
                });

            subFormGroups.forEach((subFormGroup) => {
                const control = subFormGroup.get(controlName);

                if (!control) return;

                if (control.errors && !control.errors['duplicateValues']) {
                    // return if another validator has already found an error on the control
                    return;
                }

                if (duplicateValues.includes(control.value)) {
                    control.markAsDirty();
                    control.setErrors({duplicateValues: true});
                } else {
                    control.setErrors(null);
                }
            });
            return;
        }
    }
}
