import {Component, Inject, OnInit} from '@angular/core';
import {UserModel} from '../../models/api/user.model';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {AppConstants} from '../../app.constants';
import {FormControl, FormGroup} from '@angular/forms';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {RolesService} from '../../api/services/roles.service';
import {RoleModel} from '../../models/api/role.model';
import {TeamAccountService} from '../../api/services/team-account.service';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {RLBaseComponent} from '../../components/rl-base-component/rl-base.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {UsersApiService} from '../../api/services/users.api.service';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {TableSortOptions} from '../../api/table-sort-options';
import {EFormStatus} from '../../app.enums';

export interface ITransferOwnershipFormData {
    owner: UserModel;
}

class TransferOwnershipForm {
    newOwner: FormControl<UserModel>;
    newRoles: FormControl<string[]>;
}

@Component({
    selector: 'rl-transfer-ownership-form',
    templateUrl: './transfer-ownership-form.component.html',
    styleUrls: ['./transfer-ownership-form.component.scss'],
    standalone: false
})
export class TransferOwnershipFormComponent extends RLBaseComponent implements OnInit {
    public owner: UserModel;
    public formGroup: FormGroup<TransferOwnershipForm>;
    public validationMessages: Record<string, any>;

    public roles: RoleModel[] = [];
    public users: DropdownItem<UserModel>[] = [];
    public totalUsers: number;
    public limit: number = AppConstants.PAGE_SIZE_DEFAULT;

    private saveButton: ButtonConfig;

    public usersSubscription: Subscription;
    public rolesSubscription: Subscription;

    constructor(private usersApiService: UsersApiService,
                private rolesService: RolesService,
                private teamAccountService: TeamAccountService,
                private fullModalService: FullModalService,
                @Inject(NUC_FULL_MODAL_DATA) modalData: ITransferOwnershipFormData) {
        super();
        this.owner = modalData.owner;
    }

    /**
     * On Init get users get roles and init form
     */
    public ngOnInit(): void {
        this.getUsers({limit: this.limit, offset: 0});
        this.getRoles();
        this.initModalButtons();
        this.initForm();
    }

    /**
     * Init form fields and validation messages
     */
    public initForm(): void {
        this.formGroup = new FormGroup<TransferOwnershipForm>({
            newOwner: new FormControl(null, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            newRoles: new FormControl([], RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
        })

        this.validationMessages = {
            newOwner: RLValidatorConstants.MESSAGE_SETS.REQUIRED,
            newRoles: RLValidatorConstants.MESSAGE_SETS.REQUIRED
        };

        this.formGroup.statusChanges.pipe(
            map((status) => status === EFormStatus.VALID),
            distinctUntilChanged()
        ).subscribe((valid) => this.saveButton.disabled = !valid);
    }

    /**
     * Get users paged
     * @param {IDropdownRequestDataEvent} event
     */
    public getUsers(event?: IDropdownRequestDataEvent): void {
        const excludeIds = this.owner ? [this.owner._id] : null;

        if (event?.reset) this.users = [];
        const sortOptions = new TableSortOptions([{title: 'Full name', key: 'fullName', sortProperty: 'fullName'}], 'asc');
        this.usersSubscription = this.usersApiService.getUsers(sortOptions, null, event?.limit, event?.offset, null,
            event?.search, excludeIds)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (res: ARPagedResponseDataModel<UserModel>) => {
                    this.users = this.users.concat(res.items.map((item) =>
                        new DropdownItem<UserModel>(item.fullName, item, null, null, item.email)));
                    this.totalUsers = res.total;
                },
                error: (err: ARApiError) => Toaster.handleApiError(err)
            });
    }

    /**
     * Get roles for this team
     */
    public getRoles(): void {
        this.rolesSubscription = this.rolesService.getRoles(null, null, 'name', 'asc')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (res: ARPagedResponseDataModel<RoleModel>) => this.roles = res.items,
                error: (err: ARApiError) => Toaster.handleApiError(err)
            });
    }

    /**
     * on Submit, start api call to transfer ownership
     */
    public onSubmit(): void {
        this.saveButton.loading = true;
        this.teamAccountService.transferTeamOwnership(this.formGroup.value.newOwner._id, this.formGroup.value.newRoles)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    this.saveButton.loading = false;
                    Toaster.success('Ownership has been transferred');
                    this.fullModalService.close(true);
                },
                error: (err: ARApiError) => {
                    this.saveButton.loading = false;
                    Toaster.handleApiError(err);
                    this.fullModalService.close(false);
                }
            });
    }

    private initModalButtons(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Confirm', null, null, true);
        const saveAction = new FullModalActionModel(this.saveButton);
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.subscribe(() => this.onSubmit());

        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }
}
