import {Component, DestroyRef, inject, Inject, OnInit, Optional, signal} from '@angular/core';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {IProductApiModel, ProductAssetExportModel, ProductModel} from '../../models/api/product.model';
import {FormService} from '../../api/services/form.service';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {ProductService} from '../../api/services/products.service';
import {Toaster} from '../../classes/toaster.class';
import {TabBarItemModel} from '../../models/ui/tab-bar-item.model';
import {AssetModel} from '../../models/api/asset.model';
import {DataFieldsComponentUtil} from '../../classes/data-fields-component.util';
import {finalize, map, switchMap} from 'rxjs/operators';
import {VariantModel} from '../../models/api/variant.model';
import {VariantService} from '../../api/services/variant.service';
import {forkJoin, of} from 'rxjs';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {EDataFieldCollectionName} from '../../app.enums';
import {DataFieldModel} from '../../models/api/data-field.model';
import {EFormContext, FormDetailModel} from '../../models/api/form.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
    DataFieldsInformationForm
} from '../data-fields-information-form-component/data-fields-information-form.component';
import {ProductAssetExportSortItemForm} from './product-asset-export-form/product-asset-export-form.component';
import {ProductAssetExportSetupModel} from '../../models/api/product-asset-export-setup.model';
import {ProductAssetExportSetupsService} from '../../api/services/product-asset-export-setups.service';
import {UserIsAllowedToPipe} from '../../pipes/user-is-allowed-to.pipe';
import {AppConstants} from '../../app.constants';

export interface IProductFormData {
    product?: ProductModel;
}

export class ProductForm extends DataFieldsInformationForm {
    assets?: FormControl<AssetModel[]>;
    assetExports?: FormArray<FormGroup<ProductAssetExportSortItemForm>>;
}

@Component({
    selector: 'product-form',
    templateUrl: 'product-form.component.html',
    styleUrls: ['product-form.component.scss'],
    standalone: false
})
export class ProductFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public readonly PERMISSIONS = AppConstants.PERMISSIONS;

    public TAB_PRODUCT = 0;
    public TAB_ASSETS = 1;
    public TAB_ASSET_EXPORT = 2;

    public productTab = new TabBarItemModel('Product Information', this.TAB_PRODUCT);
    public assetsTab = new TabBarItemModel('Assets', this.TAB_ASSETS);
    public assetExportTab = new TabBarItemModel('Product Asset export', this.TAB_ASSET_EXPORT);
    public tabBarItems: TabBarItemModel[] = [
        this.productTab,
        this.assetsTab,
        this.assetExportTab
    ];
    public disabledTabs: TabBarItemModel[] = []
    private _selectedTab: TabBarItemModel;
    public variants: VariantModel[];
    public formConfig: FormDetailModel;
    public selectedProductAssetExportSetupId = signal<string>(null);

    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabBarItems.find((t) => t.title === tab.title).index;
            this._selectedTab = tab;
            this._selectedTab.index = index;
            this.setConfirmButton();
        }
    }

    private confirmAction: FullModalActionModel;

    private actions: FullModalActionModel[] = [];
    private confirmButton: ButtonConfig;
    /**
     * Responder to modal confirm button click. Initialized with 'go to next tab'
     */
    private onConfirmClicked = this.nextTabAction;

    public form = new FormGroup<ProductForm>({});

    public product: ProductModel;

    public selectedAssets: AssetModel[];
    public productAssetExports: ProductAssetExportModel[];
    public dataFields: Record<string, any>;
    public productDataFields: DataFieldModel[];
    public productAssetExportSetups: ProductAssetExportSetupModel[] = [];

    constructor(private formService: FormService,
                private fullModalService: FullModalService,
                private variantService: VariantService,
                private productService: ProductService,
                private dataFieldsService: DataFieldsApiService,
                private productAssetExportSetupsService: ProductAssetExportSetupsService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) private modalData: IProductFormData) {
    }

    public ngOnInit(): void {
        forkJoin([
            this.variantService.getVariants(),
            this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.PRODUCT),
            this.formService.getForms(EFormContext.PRODUCT, 1)
                .pipe(
                    map((result) => result.items[0]),
                    switchMap((form) =>
                        form ? this.formService.getForm(form._id) : of(null))
                ),
            this.userIsAllowedToPipe.transform(this.PERMISSIONS.GET_PRODUCT_ASSET_EXPORT_SETUPS) ?
                this.productAssetExportSetupsService.findAll() : of([])
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([variants, dataFields, formConfig, productAssetExportSetups]) => {
                    this.variants = variants.items;
                    this.productDataFields = dataFields;
                    this.formConfig = formConfig;
                    this.confirmButton.disabled = false;
                    this.productAssetExportSetups = productAssetExportSetups;

                    this.selectedTab = this.productTab;
                },
                error: Toaster.handleApiError
            });

        this.initFormData();
        this.initButtons();
    }

    private initButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.confirmButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Next', null, null, true);

        const cancelAction = new FullModalActionModel(cancelButton);
        this.confirmAction = new FullModalActionModel(this.confirmButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        this.confirmAction.observable.subscribe(() => this.onConfirmClicked());

        this.actions = [cancelAction, this.confirmAction];
        this.fullModalService.setModalActions(this.actions);
    }

    private initFormData(): void {
        this.product = this.modalData?.product;
        this.dataFields = this.product?.dataFields || {};
        this.selectedAssets = this.product?.assets || [];
        this.productAssetExports = this.product?.assetExports || [];
    }

    private nextTabAction(): void {
        this.selectedTab = this.tabBarItems.find((tab) => tab.index === this.selectedTab.index + 1 && !tab.disabled);
    }

    /**
     * If current tab is information text is Next and action is 'go to next tab'
     * Otherwise text is 'Save' and action is 'Save'
     */
    private setConfirmButton(): void {
        if (this.tabBarItems.find(tab => tab.index > this._selectedTab.index && !tab.disabled)) {
            this.confirmAction.button.text = 'Next';
            this.onConfirmClicked = this.nextTabAction;
        } else {
            this.confirmAction.button.text = 'Save';
            this.onConfirmClicked = this.onSaveButtonClicked;
        }
    }

    private onSaveButtonClicked(): void {
        if (this.form?.valid) {
            this.saveProduct();
        } else {
            Toaster.warn('Form contains errors. Please update incorrect and/or missing values');
            Object.keys(this.form.controls).forEach((key) => this.form.get(key).markAsDirty());
        }
    }

    private saveProduct(): void {
        this.confirmButton.loading = true;
        const formattedForm = this.getUpdateBody();

        const observable = this.product ?
            this.productService.patchProduct(this.product._id, formattedForm) :
            this.productService.postProduct(formattedForm);

        observable
            .pipe(
                finalize(() => this.confirmButton.loading = false),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: (result) => {
                    Toaster.success(this.product ?
                        'Product updated successfully' :
                        'Product added successfully');
                    this.fullModalService.close(result)
                },
                error: Toaster.handleApiError
            });
    }

    /**
     * Returns all non-null values in form
     */
    private getUpdateBody(): IProductApiModel {
        try {
            const dataFields = DataFieldsComponentUtil.serializeDataFieldsForApiBody(DataFieldsComponentUtil.getBodyForDataFields(
                this.form.value.dataFields, !!this.product), this.productDataFields);
            const assetExports = this.form.value.assetExports ? this.form.value.assetExports.map(assetExport => {
                return {
                    productAssetExportSetup: assetExport.productAssetExportSetup._id,
                    items: assetExport.items
                        .filter(item => !!item.asset)
                        .map(item => {
                            return {
                                sortItem: item.sortItem._id,
                                asset: item.asset?._id
                            }
                        })
                };
            }).filter(productAssetExport => productAssetExport.items.length) : undefined;

            return {
                dataFields,
                ...(this.form.value.assets && {assets: this.form.value.assets?.map((asset: AssetModel) => asset._id)}),
                ...(assetExports && {assetExports})
            };
        } catch (error) {
            Toaster.handleApiError(error);
        }
    }
}
