import {Component, DestroyRef, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {EEngineType, TemplateModel} from '../../models/api/template.model';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {PublicationsService} from '../../api/services/publications.service';
import {distinctUntilChanged, finalize, map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {RLValidatorRegExConstants} from '../../classes/validators/rl-validator-regex.constant';
import {EChannel, EFormStatus} from '../../app.enums';
import {IDropdownItem, IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {TemplatesApiService} from '../../api/services/templates.api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export interface IAddPageActionOptions {
    engineTypes: EEngineType[];
    templateFilterCampaignTags: string[];
    templateFilterTemplateTypes: string[];
    templateFilterTemplateTags: string[];
    channel: EChannel;
}

export interface IAddPageFormData {
    selectedPageType: number;
    publicationId: string;
    transition: string;
    filterOptions: IAddPageActionOptions;
}

interface IAddPageForm {
    template: FormControl<string>;
    amount: FormControl<number>;
}

@Component({
    selector: 'rl-add-page-form-component',
    templateUrl: 'add-page-form.component.html',
    styleUrls: ['add-page-form.component.scss'],
    standalone: false
})

export class AddPageFormComponent implements OnInit {
    public templateOptions: IDropdownItem<string>[] = [];
    public formGroup: FormGroup<IAddPageForm>;
    private search: string;
    private offset: number;
    public totalItems = 0;
    public addButton: ButtonConfig;
    public templatesSubscription: Subscription;

    constructor(private templatesApiService: TemplatesApiService,
                private publicationService: PublicationsService,
                private fullModalService: FullModalService,
                private destroyRef: DestroyRef,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IAddPageFormData) {
    }

    public ngOnInit(): void {
        this.initButtons();
        this.getTemplates();
        this.initForm();
    }

    public initButtons(): void {
        this.addButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Add', null, null, true);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const cancel = new FullModalActionModel(cancelButton);
        const add = new FullModalActionModel(this.addButton);

        cancel.observable.subscribe(() => this.fullModalService.close(false, true));
        add.observable.subscribe(() => this.onAddClicked());

        const actions = [cancel, add];
        this.fullModalService.setModalActions(actions);
    }

    public initForm(): void {
        this.formGroup = new FormGroup({
            template: new FormControl(null, Validators.required),
            amount: new FormControl(1,
                [Validators.required, Validators.min(1), Validators.max(100), Validators.pattern(RLValidatorRegExConstants.INTEGER)])
        });

        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            map((status) => status === EFormStatus.VALID),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((isValid: boolean) => this.addButton.disabled = !isValid);
    }

    private getTemplates(): void {
        this.templatesSubscription?.unsubscribe();

        const tags = [...this.modalData.filterOptions.templateFilterCampaignTags, ...this.modalData.filterOptions.templateFilterTemplateTags];
        this.templatesSubscription = this.templatesApiService.getTemplates(
            null,
            this.offset,
            null,
            null,
            this.search,
            {
                channel: this.modalData.filterOptions.channel,
                engineTypes: this.modalData.filterOptions.engineTypes,
                numberOfPages: this.modalData.selectedPageType,
                tags: tags.length ? tags : null
            })
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result: ARPagedResponseDataModel<TemplateModel>) => {
                    this.templateOptions = this.templateOptions.concat(...result.items);
                    this.totalItems = result.total;
                },
                error: Toaster.handleApiError
            });
    }

    public onAddClicked(): void {
        this.addButton.loading = true;
        const templateId = this.formGroup.value.template;
        const amount = this.formGroup.value.amount;
        const data = {items: Array(amount).fill({templateId})};

        this.publicationService.postTransitionItem(this.modalData.publicationId, this.modalData.transition, data)
            .pipe(finalize(() => {
                this.addButton.loading = false;
                this.fullModalService.close();
            }))
            .subscribe({
                error: (error) => Toaster.handleApiError(error)
            });
    }

    public searchTemplates(event: IDropdownRequestDataEvent): void {
        if (event.reset) {
            this.templateOptions = [];
        }

        this.offset = event.offset;
        this.search = event.search;

        this.getTemplates();
    }
}
