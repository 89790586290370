import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {ApiService} from './api.service';
import {ProjectModel} from '../../models/api/project.model';
import {Observable} from 'rxjs';
import {JobModel} from '../../models/api/job.model';
import {ApiPostJobRequest} from '../requests/api-post-job.request';
import {tap} from 'rxjs/operators';
import {MonitoredJobsService} from './monitored-updates/monitored-jobs.service';

export enum EProjectJobTypes {
    DELETE_PROJECT_JOB = 'DELETE_PROJECT_JOB',
}

export interface IProjectDeleteJobData {
    projectId: string;
}

@Injectable({
    providedIn: 'root'
})
export class ProjectsApiService extends ApiService<ProjectModel> {

    constructor(private monitoredJobsService: MonitoredJobsService) {
        super([ApiConstants.API_GROUP_PROJECTS], ProjectModel);
    }

    public postJob(jobType: EProjectJobTypes, jobData: IProjectDeleteJobData): Observable<JobModel> {
        const request = new ApiPostJobRequest(this.groups, JobModel, jobType, jobData);
        return this.apiRequestService.postJob(request)
            .pipe(tap((job: JobModel) => this.monitoredJobsService.addItemToMonitor(job)));
    }
}
