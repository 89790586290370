import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {PackageSetupModel} from '../../../models/api/package-setup.model';
import {ETabFormGroup} from '../package-setup-form.component';
import {RLValidatorConstants} from '../../../classes/validators/rl-validators.constant';

@Component({
    selector: 'package-setup-information-form-component',
    templateUrl: 'package-setup-information-form.component.html',
    styleUrls: ['package-setup-information-form.component.scss'],
    standalone: false
})

export class PackageSetupInformationFormComponent implements OnInit {
    @Input() public form: UntypedFormGroup;
    @Input() public packageSetup: PackageSetupModel;

    public packageSetupGroup: UntypedFormGroup;

    public ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        if (!this.form.contains(ETabFormGroup.PACKAGE_SETUP)) {
            this.packageSetupGroup = new UntypedFormGroup({
                name: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                description: new UntypedFormControl()
            });
            this.form.addControl(ETabFormGroup.PACKAGE_SETUP, this.packageSetupGroup);

            if (this.packageSetup) {
                const data = {
                    [ETabFormGroup.PACKAGE_SETUP]: {
                        name: this.packageSetup.name,
                        description: this.packageSetup.description
                    }
                };
                this.form.patchValue(data);
            }
        } else {
            this.packageSetup = this.form.get(ETabFormGroup.PACKAGE_SETUP).value;
            this.packageSetupGroup = this.form.get(ETabFormGroup.PACKAGE_SETUP) as UntypedFormGroup;
        }
    }
}
