import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {CampaignItemModel} from '../../../models/api/campaign-item.model';
import {CampaignItemsService} from '../../../api/services/campaign-items.service';
import {ProductModel} from '../../../models/api/product.model';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {VariantModel} from '../../../models/api/variant.model';
import {VariantService} from '../../../api/services/variant.service';
import {CampaignItemFormComponentDirective} from '../campaign-item-form-component.directive';
import {forkJoin, of, Subscription} from 'rxjs';
import {EFormContext, FormDetailModel} from '../../../models/api/form.model';
import {map, switchMap} from 'rxjs/operators';
import {FormService} from '../../../api/services/form.service';
import {Toaster} from '../../../classes/toaster.class';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export interface ICampaignItemFormData {
    campaignId: string;
    campaignItem?: CampaignItemModel;
}

@Component({
    selector: 'campaign-item-modal-form',
    templateUrl: './campaign-item-modal-form.component.html',
    styleUrls: ['./campaign-item-modal-form.component.scss'],
    standalone: false
})
export class CampaignItemModalFormComponent extends CampaignItemFormComponentDirective implements OnInit {
    public dataFields: Record<string, any>;
    public selectedProducts: ProductModel[] = [];
    public variants: VariantModel[];
    public formConfig: FormDetailModel;
    public dataSubscription: Subscription;

    constructor(private fullModalService: FullModalService,
                campaignItemService: CampaignItemsService,
                private variantService: VariantService,
                private formService: FormService,
                userIsAllowedToPipe: UserIsAllowedToPipe,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) public modalData: ICampaignItemFormData) {
        super(campaignItemService, userIsAllowedToPipe);

        this.campaignItem = this.modalData.campaignItem;
        this.campaignId = this.modalData.campaignId;
    }

    public ngOnInit(): void {
        this.setupForm();
    }

    protected initButtons(): void {
        super.initButtons();

        if (this.readonly) {
            this.fullModalService.setTitle('View briefing item');
            this.fullModalService.setConfirmClose(false);
        }
        this.fullModalService.setModalActions(this.actions);
    }

    protected initFormData(): void {
        this.dataSubscription = forkJoin([
            this.variantService.getVariants(this.campaignId),
            this.formService.getForms(EFormContext.BRIEFING, 1)
                .pipe(
                    map((result) => result.items[0]),
                    switchMap((form) =>
                        form ? this.formService.getForm(form._id) : of(null))
                )
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([variantsResult, formConfig]) => {
                    this.variants = variantsResult.items;
                    this.formConfig = formConfig;
                    this.selectedProducts = this.campaignItem?.products || [];
                    this.dataFields = this.campaignItem?.dataFields || {};
                    this.confirmButton.disabled = false;
                },
                error: Toaster.handleApiError
            });
    }

    public beforeCloseForm(result?: CampaignItemModel, confirmClose?: boolean) {
        this.closeForm(result, confirmClose);
    }

    protected closeForm(result?: CampaignItemModel, confirmClose?: boolean) {
        this.fullModalService.close(result, confirmClose);
    }
}
