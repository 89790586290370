import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA,
    NucIcons
} from '@relayter/rubber-duck';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {Toaster} from '../../classes/toaster.class';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';
import {
    WORKFLOW_CONFIGURATION_ACTIONS_CONFIGS,
    WorkflowConfigurationActionModel,
    EBriefingActionName,
    ETransitionTriggerActionName,
    EWorkflowConfigurationActionType,
    IActionName,
    IActionOptionConfig
} from '../../models/api/workflow-configuration-action.model';
import {EChannel, EDataCollectionName, EDataFieldCollectionName, EFormStatus, EStickyNoteStatus} from '../../app.enums';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {PermissionsService} from '../../api/services/permissions.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ModelUtil} from '../../classes/model.util';
import {WorkflowConfigurationStepModel} from '../../models/api/workflow-configuration-step.model';
import {
    WORKFLOW_CONFIGURATION_COMPONENT_TYPES_CONFIGS,
    WorkflowConfigurationComponentModel,
    IAllowedActionConfig
} from '../../models/api/workflow-configuration-component.model';
import {combineLatest, distinctUntilChanged, map, startWith, Subscription} from 'rxjs';
import {
    WorkflowConfigurationOptionModel,
    EWorkflowConfigurationActionOptionDisplayType,
    EWorkflowConfigurationActionOptionName,
    EWorkflowConfigurationActionOptionNotificationReceiver,
    EWorkflowConfigurationActionOptionSignOffs
} from '../../models/api/workflow-configuration-option.model';
import {EEngineType} from '../../models/api/template.model';
import {
    EPackageOptions,
    EPackageType
// eslint-disable-next-line max-len
} from '../../pages/relayter/campaigns/publication/custom-workflow/custom-workflow-files/custom-workflow-files-download/package-type/files-download-package-type.component';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {StringUtil} from '../../classes/string-util';
import {TemplateTypeService} from '../../api/services/template-types.service';
import {DataCollectionService} from '../../api/services/data-collection.service';
import {
    EScaleTypes,
    ICustomPackageFormGroup
} from './workflow-configuration-action-custom-package-form/workflow-configuration-action-custom-package-form.component';
import {
    EUploadCategory
} from '../../pages/relayter/campaigns/publication/custom-workflow/custom-workflow-files/custom-workflow-upload/upload-group.model';

export interface IWorkflowConfigurationActionFormData {
    workflowConfiguration: WorkflowConfigurationModel;
    action?: WorkflowConfigurationActionModel;
    step?: WorkflowConfigurationStepModel;
    component?: WorkflowConfigurationComponentModel;
}

@Component({
    selector: 'workflow-configuration-action-form-component',
    templateUrl: 'workflow-configuration-action-form.component.html',
    styleUrls: ['workflow-configuration-action-form.component.scss'],
    standalone: false
})
export class WorkflowConfigurationActionFormComponent implements OnInit {
    private destroyRef: DestroyRef = inject(DestroyRef);
    private templateTypeService = inject(TemplateTypeService);
    private dataCollectionService = inject(DataCollectionService);

    public permissions: DropdownItem<string>[];

    public readonly transitions: DropdownItem<string>[];
    public transitionOptions: DropdownItem<string>[];

    public readonly nucIcons: DropdownItem<string>[];
    public nucIconOptions: DropdownItem<string>[] = [];

    public names: DropdownItem<string>[] = [];
    public nameOptions: DropdownItem<string>[] = [];

    public actionTypeOptions: DropdownItem<EWorkflowConfigurationActionType>[];
    public allowedActionsConfig: IAllowedActionConfig[];

    public formGroup: FormGroup;
    private saveButton: ButtonConfig;

    public optionsFormGroup: FormGroup;
    public EWorkflowConfigurationActionOptionName = EWorkflowConfigurationActionOptionName

    private workflowConfiguration: WorkflowConfigurationModel;
    public action: WorkflowConfigurationActionModel;

    public readonly noteToStatus = Object.values(EStickyNoteStatus)
        .filter(status => ![EStickyNoteStatus.NEW, EStickyNoteStatus.CREATED].includes(status))
        .sort().map(status => new DropdownItem<EStickyNoteStatus>(status, status));

    public readonly noteFromStatus = Object.values(EStickyNoteStatus)
        .filter(status => ![EStickyNoteStatus.NEW].includes(status))
        .sort().map(status => new DropdownItem<EStickyNoteStatus>(status, status));

    public readonly engineTypes = Object.values(EEngineType).map((value) => new DropdownItem<EEngineType>(value, value));
    public readonly channels = Object.values(EChannel).map((value) => new DropdownItem<EChannel>(value, value));

    // all the campaign item dataFields field key collection
    // either with 'dataFields.' or without, we should align it
    public displayOptions: DropdownItem<string>[];
    public campaignItemFieldOptions: DropdownItem<string>[];
    public templateTypeOptions: DropdownItem<string>[];
    public templateTagsOptions: DropdownItem<any>[];
    public requiredFilesOptions = [
        new DropdownItem<EUploadCategory>('Export file', EUploadCategory.EXPORT),
        new DropdownItem<EUploadCategory>('Source file', EUploadCategory.SOURCE)
    ];
    public displayType: EWorkflowConfigurationActionOptionDisplayType; // temporary solution
    protected readonly EWorkflowConfigurationActionOptionDisplayType = EWorkflowConfigurationActionOptionDisplayType;

    // sign off (publication item approval) options
    public signOffOptions: DropdownItem<string>[] = [
        new DropdownItem<string>('Keep approvals', EWorkflowConfigurationActionOptionSignOffs.KEEP),
        new DropdownItem<string>('Remove approvals', EWorkflowConfigurationActionOptionSignOffs.REMOVE),
        new DropdownItem<string>('Show confirmation popup', EWorkflowConfigurationActionOptionSignOffs.SHOW_CONFIRM),
    ];

    public readonly packageOptions = Object.values(EPackageType)
        .filter((value) => value !== EPackageType.CUSTOM_EXPORT_PACKAGE)
        .map((value) => new DropdownItem<EPackageType>(value, value));

    public scaleTypes = [
        new DropdownItem('Box size', EScaleTypes.BOX_SIZE, false, undefined, 'Fit the image in the requested box, maintaining the aspect ratio'),
        new DropdownItem('Scale', EScaleTypes.SCALE, false, undefined, 'Resize the image to the requested size, using % or absolute numbers'),
    ];
    public formatOptions = ['PNG', 'JPG', 'JPEG', 'TIF', 'TIFF', 'GIF'].map((v) => new DropdownItem(v, v));

    public sendNotificationControlSubscription: Subscription;

    public notificationOptionValue: any;

    get customPackagesFormArray(): FormArray {
        return (this.optionsFormGroup.controls[EWorkflowConfigurationActionOptionName.PACKAGES] as FormGroup).controls['customPackages'] as FormArray;
    }

    constructor(private fullModalService: FullModalService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private dataFieldsService: DataFieldsApiService,
                private permissionService: PermissionsService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowConfigurationActionFormData) {
        this.workflowConfiguration = this.modalData.workflowConfiguration;
        this.action = this.modalData.action || new WorkflowConfigurationActionModel();

        this.transitions = this.workflowConfiguration.transitions
            .filter((transition) =>  !this.modalData.step || transition.from === this.modalData.step._id)
            .map(transition =>
                new DropdownItem(transition.name, transition._id));

        this.transitionOptions = this.transitions;

        this.nucIcons = [...NucIcons].sort().map(nucIcon =>
            new DropdownItem(
                nucIcon,
                `nucicon_${nucIcon}`,
                null,
                `nucicon_${nucIcon}`
            ));

        this.allowedActionsConfig = this.modalData.component ? WORKFLOW_CONFIGURATION_COMPONENT_TYPES_CONFIGS.find((config) =>
                config.type === this.modalData.component.componentType).allowedActionsConfig : [{ actionType:
            EWorkflowConfigurationActionType.TRANSITION_TRIGGER, names: [ETransitionTriggerActionName.TRANSITION_TRIGGER] }];

        // filter out sequentialNumbering required actions
        if (!this.workflowConfiguration.sequentialNumbering) {
            this.allowedActionsConfig = this.allowedActionsConfig.filter((config) => {
                config.names = config.names.filter((actionName) => {
                    return !WORKFLOW_CONFIGURATION_ACTIONS_CONFIGS.find((eachConfig) => eachConfig.name === actionName).sequentialNumberingRequired;
                });
                return config.names.length > 0;
            });
        }

        this.actionTypeOptions = this.allowedActionsConfig.filter((item) => !!item.actionType)
            .map((item) => new DropdownItem<EWorkflowConfigurationActionType>(item.actionType, item.actionType));
    }

    public ngOnInit(): void {
        this.initData();
        this.initModalButtons();
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, false, true);

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveWorkflowConfigurationAction());
        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private initData(): void {
        this.nucIconOptions = [...this.nucIcons];

        combineLatest([
            this.permissionService.getAllPermissions('key', 'asc'),
            this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM),
            this.templateTypeService.getTemplateTypes(),
            this.dataCollectionService.getDataCollectionValues(EDataCollectionName.TEMPLATE, 'tags', null)
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([permissions, campaignItemDataFields, templateTypes, templateTags]) => {
                    this.permissions = permissions.map(permission => new DropdownItem(
                        permission._id,
                        permission._id
                    ));
                    this.displayOptions = campaignItemDataFields.map((field) => new DropdownItem(field.name, field.fieldName));
                    this.campaignItemFieldOptions = campaignItemDataFields.map((field) =>
                        new DropdownItem(field.name, `dataFields.${field.fieldName}`));
                    this.templateTypeOptions = templateTypes.items.map((templateType) => new DropdownItem(templateType.name, templateType._id));
                    this.templateTagsOptions = templateTags.items.map((templateTag) => new DropdownItem(templateTag.title, templateTag.value));

                    this.initForm();
                    this.listenToFormStatus();
                    this.listenToTypeControl();
                    this.listenToNameControl();
                },
                error: Toaster.handleApiError
            });
    }

    private initForm(): void {
        // set the default type GENERAL_ACTION
        const type = this.action?.type || EWorkflowConfigurationActionType.GENERAL_ACTION;

        this.names = (this.allowedActionsConfig.find((config) => config.actionType === type)?.names || [])
            .map((name) => WORKFLOW_CONFIGURATION_ACTIONS_CONFIGS.find((config) => config.name === name))
            .map((config) => new DropdownItem<string>(config.title, config.name));

        this.nameOptions = [...this.names];

        this.formGroup = new FormGroup<any>({
            name: new FormControl(this.action.name, Validators.required),
            title: new FormControl(this.action.title),
            type: new FormControl(type),
            permissions: new FormControl(this.action.permissions || []),
            icon: new FormControl(this.action?.icon, Validators.required)
        });

        // TODO: remove the later part when we have a better solution for BRIEFING_ACTION with transition
        if (type === EWorkflowConfigurationActionType.TRANSITION_TRIGGER || this.action.name === EBriefingActionName.GENERATE) {
            this.formGroup.addControl('transition', new FormControl(this.action?.transition, Validators.required));
        }

        const actionConfig = WORKFLOW_CONFIGURATION_ACTIONS_CONFIGS.find((config) => config.name === this.action.name);

        if (actionConfig?.fromApplicable) {
            this.formGroup.addControl('from', new FormControl(this.action.from));
        }

        if (actionConfig?.toApplicable) {
            this.formGroup.addControl('to', new FormControl(this.action.to, Validators.required));
        }

        if (actionConfig?.optionsConfig) this.addOptionsFormGroup(actionConfig.optionsConfig);
    }

    private addOptionsFormGroup(optionsConfig: IActionOptionConfig[]): void {

        this.optionsFormGroup = new FormGroup({});

        for (const optionConfig of optionsConfig) {
            const value = this.action.options?.find((option) => optionConfig.name === option.name)?.value;

            switch (optionConfig.name) {
                // all boolean values
                case EWorkflowConfigurationActionOptionName.ADD_PACKAGE_DATA:
                case EWorkflowConfigurationActionOptionName.ADD_NOTES:
                case EWorkflowConfigurationActionOptionName.AUTO_ASSIGN:
                case EWorkflowConfigurationActionOptionName.TEMPLATE_FILTER_CAMPAIGN_TAGS:
                case EWorkflowConfigurationActionOptionName.ADD_FOR_RELATED_PUBLICATION_ITEMS: {
                    this.optionsFormGroup.addControl(optionConfig.name, new FormControl(value));
                    break;
                }
                case EWorkflowConfigurationActionOptionName.ENGINE_TYPES: {
                    this.optionsFormGroup.addControl(optionConfig.name, new FormControl(value || []));
                    break;
                }
                case EWorkflowConfigurationActionOptionName.REMOVE_SIGN_OFFS: {
                    this.optionsFormGroup.addControl(optionConfig.name, new FormControl(value, Validators.required));
                    break;
                }
                case EWorkflowConfigurationActionOptionName.DISPLAY: {
                    this.displayType = optionConfig.type as EWorkflowConfigurationActionOptionDisplayType;
                    // currently we need to switch ¯\_(ツ)_/¯
                    if (optionConfig.type === EWorkflowConfigurationActionOptionDisplayType.MULTI) {
                        this.optionsFormGroup.addControl(optionConfig.name, new FormControl(value || []));
                    } else {
                        this.optionsFormGroup.addControl(optionConfig.name, new FormControl(value));
                    }
                    break;
                }
                case EWorkflowConfigurationActionOptionName.CAMPAIGN_ITEM_FIELDS: {
                    this.optionsFormGroup.addControl(optionConfig.name, new FormControl(value || []));
                    break;
                }
                case EWorkflowConfigurationActionOptionName.PACKAGES: {
                    const selectedValues = value
                        ? this.packageOptions.filter((option) => value?.map((v) => v.type).includes(option))
                        : [];

                    const packageFormGroup = new FormGroup({
                        packageTypes: new FormControl(selectedValues),
                        customPackages: new FormArray([])
                    });

                    this.optionsFormGroup.addControl(optionConfig.name, packageFormGroup);
                    // add custom packages
                    (value || [])?.filter((v) => v.type === EPackageType.CUSTOM_EXPORT_PACKAGE)
                        .forEach((customPackage) => this.addCustomPackageFormGroup(customPackage.options));
                    break;
                }
                case EWorkflowConfigurationActionOptionName.NOTIFICATION: {
                    this.notificationOptionValue = value || {};
                    this.notificationOptionValue.type = optionConfig.type; // make sure type is correct

                    const sendNotificationsControl = new FormControl(!!value);
                    const notificationFormGroup = new FormGroup({
                        sendNotifications: sendNotificationsControl
                    });

                    this.optionsFormGroup.addControl(optionConfig.name, notificationFormGroup);

                    // avoid memory leak
                    this.sendNotificationControlSubscription?.unsubscribe();
                    this.sendNotificationControlSubscription = sendNotificationsControl.valueChanges
                        .pipe(
                            startWith(!!value),
                            takeUntilDestroyed(this.destroyRef)
                        )
                        .subscribe((value: boolean) => {
                            const formGroup = this.optionsFormGroup.controls[optionConfig.name] as FormGroup;
                            if (value) {
                                formGroup.addControl('notificationData', new FormGroup({}));
                            } else {
                                formGroup.removeControl('notificationData');
                            }
                        });
                    break;
                }
                case EWorkflowConfigurationActionOptionName.CHANNEL: {
                    this.optionsFormGroup.addControl(optionConfig.name, new FormControl(value));
                    break;
                }
                case EWorkflowConfigurationActionOptionName.TEMPLATE_FILTER_TEMPLATE_TAGS: {
                    this.optionsFormGroup.addControl(optionConfig.name, new FormControl(value || []));
                    break;
                }
                case EWorkflowConfigurationActionOptionName.TEMPLATE_FILTER_TEMPLATE_TYPES: {
                    this.optionsFormGroup.addControl(optionConfig.name, new FormControl(value || []));
                    break;
                }
                case EWorkflowConfigurationActionOptionName.REQUIRED_FILES: {
                    this.optionsFormGroup.addControl(optionConfig.name, new FormControl(value || []));
                    break;
                }
                default:
                    Toaster.error(`Workflow action option: ${optionConfig.name} is not supported yet.`);
            }
        }

        // this is to help with the save button status, otherwise we can use two forms
        this.formGroup.addControl('options', this.optionsFormGroup);
    }

    private listenToFormStatus(): void {
        // only to the form after everything is initialized
        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            map((status) => status === EFormStatus.VALID),
            startWith(this.formGroup.status === EFormStatus.VALID),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((valid) => this.saveButton.disabled = !valid);
    }

    private listenToTypeControl(): void {
        this.formGroup.get('type').valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((value) => {
                this.names = this.allowedActionsConfig.find((config) => config.actionType === value)
                    .names.map((name) => WORKFLOW_CONFIGURATION_ACTIONS_CONFIGS.find((config) => config.name === name))
                    .map((config) => new DropdownItem<string>(config.title, config.name));

                this.nameOptions = [...this.names];

                // always reset the name when action type is changed
                this.formGroup.patchValue({name: null});

                if (value === EWorkflowConfigurationActionType.TRANSITION_TRIGGER) {
                    this.formGroup.addControl('transition', new FormControl('', Validators.required));
                } else {
                    this.formGroup.removeControl('transition');
                }
            });
    }

    private listenToNameControl(): void {
        this.formGroup.get('name').valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((name: IActionName) => {
                // TODO: eventually remove this block. Currently GENERATE is a briefing action with transition ¯\_(ツ)_/¯
                if (name === EBriefingActionName.GENERATE) {
                    this.formGroup.addControl('transition', new FormControl('', Validators.required));
                } else {
                    // if necessary remove the control
                    if (this.formGroup.value.type !== EWorkflowConfigurationActionType.TRANSITION_TRIGGER) {
                        this.formGroup.removeControl('transition');
                    }
                }

                const actionConfig = WORKFLOW_CONFIGURATION_ACTIONS_CONFIGS.find((config) => config.name === name);

                if (actionConfig?.fromApplicable) {
                    const statusFrom = this.noteFromStatus.filter(status => this.action.from?.includes(status.getValue()));
                    this.formGroup.addControl('from', new FormControl(statusFrom));
                } else {
                    this.formGroup.removeControl('from');
                }

                if (actionConfig?.toApplicable) {
                    this.formGroup.addControl('to', new FormControl(this.action.to, Validators.required));
                } else {
                    this.formGroup.removeControl('to');
                }

                // remove first
                this.formGroup.removeControl('options');
                this.optionsFormGroup = null; // reset the optionsFormGroup

                if (actionConfig?.optionsConfig) this.addOptionsFormGroup(actionConfig.optionsConfig);
            });
    }

    private saveWorkflowConfigurationAction(): void {
        const formValue = this.formGroup.value;

        const action = ModelUtil.createApiBody({
            name: formValue.name,
            title: formValue.title,
            type: formValue.type === EWorkflowConfigurationActionType.GENERAL_ACTION ? null : formValue.type,
            from: formValue.from || [],
            to: formValue.to,
            transition: formValue.transition,
            icon: formValue.icon,
            permissions: formValue.permissions || [],
            options: this.optionsFormGroup ? this.createOptionsBody() : []
        }, this.action._id);

        if (this.action._id) {
            this.workflowConfigurationService.patchWorkflowConfigurationAction(this.workflowConfiguration._id,
                this.action._id, action, this.modalData.component?._id, this.modalData.step?._id)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Action updated successfully');
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.workflowConfigurationService.createWorkflowConfigurationAction(this.workflowConfiguration._id, action,
                this.modalData.component?._id, this.modalData.step?._id)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: workflowConfiguration => {
                        this.fullModalService.close(workflowConfiguration);
                        Toaster.success('Action created successfully');
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    private createOptionsBody(): WorkflowConfigurationOptionModel[] {
        const formattedOptions = [];

        for (const [optionName, value] of Object.entries(this.optionsFormGroup.value)) {
            if (!value || (Array.isArray(value) && value.length === 0)) continue;

            switch (optionName) {
                case EWorkflowConfigurationActionOptionName.ADD_PACKAGE_DATA:
                case EWorkflowConfigurationActionOptionName.ADD_NOTES:
                case EWorkflowConfigurationActionOptionName.AUTO_ASSIGN:
                case EWorkflowConfigurationActionOptionName.TEMPLATE_FILTER_CAMPAIGN_TAGS:
                case EWorkflowConfigurationActionOptionName.ADD_FOR_RELATED_PUBLICATION_ITEMS: {
                    // boolean field
                    formattedOptions.push({name: optionName, value});
                    break;
                }
                case EWorkflowConfigurationActionOptionName.CHANNEL:
                    // single select
                    formattedOptions.push({name: optionName, value});
                    break;
                case EWorkflowConfigurationActionOptionName.REMOVE_SIGN_OFFS:
                    // single select
                    formattedOptions.push({name: optionName, value});
                    break;
                case EWorkflowConfigurationActionOptionName.DISPLAY:
                    // we trust the form group
                    formattedOptions.push({
                        name: optionName,
                        value: value
                    });
                    break;
                case EWorkflowConfigurationActionOptionName.TEMPLATE_FILTER_TEMPLATE_TAGS:
                case EWorkflowConfigurationActionOptionName.TEMPLATE_FILTER_TEMPLATE_TYPES:
                case EWorkflowConfigurationActionOptionName.CAMPAIGN_ITEM_FIELDS:
                case EWorkflowConfigurationActionOptionName.ENGINE_TYPES:
                case EWorkflowConfigurationActionOptionName.REQUIRED_FILES:
                    formattedOptions.push({
                        name: optionName,
                        value: value
                    });
                    break;
                case EWorkflowConfigurationActionOptionName.PACKAGES:
                    // get the package form
                    const packageTypes = value['packageTypes'].map((v) => ({type: v}));

                    if (value['customPackages']) {
                        value['customPackages'].forEach((customPackageValue: Record<EPackageOptions, any>) => {
                            const customExportType = {type: EPackageType.CUSTOM_EXPORT_PACKAGE};
                            customExportType['options'] = Object.keys(EPackageOptions).map((name) => {
                                switch(name) {
                                    case EPackageOptions.SIZE: {
                                        return {
                                            name,
                                            value: {
                                                scaleType: customPackageValue[name].scaleType,
                                                height: customPackageValue[name].height,
                                                width: customPackageValue[name].width
                                            }
                                        }
                                    }
                                    case EPackageOptions.FORMAT: {
                                        return {name, value: customPackageValue[name]};
                                    }
                                    case EPackageOptions.NAME:
                                    case EPackageOptions.DESCRIPTION:
                                        return {name, value: customPackageValue[name]};
                                }
                            });
                            packageTypes.push(customExportType);
                        })
                    }
                    formattedOptions.push({name: optionName, value: packageTypes});
                    break;
                case EWorkflowConfigurationActionOptionName.NOTIFICATION:
                    // when sendNotification checkbox is not toggled we skip this action option
                    if (!value['sendNotifications']) break;

                    const notificationValue = value['notificationData'];

                    const receivers = notificationValue['receivers'].map((receiver) => {
                        const type = receiver.type;
                        const result = {type};
                        if (type === EWorkflowConfigurationActionOptionNotificationReceiver.NOTE_CHANGELOG) {
                            result['property'] = 'status';
                            result['value'] = receiver.status;
                        }
                        if (type === EWorkflowConfigurationActionOptionNotificationReceiver.USER_IDS) {
                            result['value'] = receiver.users;
                        }
                        return result;
                    });

                    const campaignItemDataFields = notificationValue['campaignItemDataFields'];

                    formattedOptions.push({
                        name: optionName,
                        value: {
                            type: this.notificationOptionValue.type,
                            actionName: this.formGroup.value.title,
                            campaignItemDataFields,
                            receivers: receivers
                        }
                    });

                    break;
                default:
                    Toaster.error(`Workflow action option: ${optionName} is not supported yet.`);
                    break;
            }
        }
        return formattedOptions;

    }

    public searchTransitions(event: IDropdownRequestDataEvent): void {
        if (event.reset) this.transitionOptions = [];

        if (event.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(event.search), 'i');
            this.transitionOptions = this.transitions.filter((transition) =>
                transition.getTitle().match(regex)?.length > 0);
        } else {
            this.transitionOptions = this.transitions;
        }
    }

    public searchIcons(event: IDropdownRequestDataEvent): void {
        if (event.reset) this.nucIconOptions = [];

        if (event.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(event.search), 'i');
            this.nucIconOptions = this.nucIcons.filter((icon) =>
                icon.getTitle().match(regex)?.length > 0);
        } else {
            this.nucIconOptions = this.nucIcons;
        }
    }

    public searchNames(event: IDropdownRequestDataEvent): void {
        if (event.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(event.search), 'i');
            this.nameOptions = this.names.filter((name) =>
                name.getTitle().match(regex)?.length > 0 || name.getValue().match(regex)?.length > 0)
        } else {
            this.nameOptions = this.names;
        }
    }

    readonly regexValidator = Validators.pattern('^(?!0+$)\\d+%?$');
    public addCustomPackageFormGroup(value?: any[]): void {
        const valueObj = value ? Object.keys(EPackageOptions).reduce((obj, key) => {
            obj[key] = (value || []).find((v) => v.name === key)?.value;
            return obj;
        }, {}) : {};

        const formGroup = new FormGroup<ICustomPackageFormGroup>({
            NAME: new FormControl(valueObj[EPackageOptions.NAME], Validators.required),
            DESCRIPTION: new FormControl(valueObj[EPackageOptions.DESCRIPTION], Validators.required),
            FORMAT: new FormControl(valueObj[EPackageOptions.FORMAT], Validators.required),
            SIZE: new FormGroup({
                scaleType: new FormControl(valueObj[EPackageOptions.SIZE]?.scaleType, Validators.required),
                width: new FormControl(valueObj[EPackageOptions.SIZE]?.width, Validators.required),
                height: new FormControl(valueObj[EPackageOptions.SIZE]?.height, Validators.required)
            })
        });

        if (formGroup.controls.SIZE.controls.scaleType?.value === EScaleTypes.SCALE) {
            formGroup.controls.SIZE.controls.height.addValidators(this.regexValidator);
            formGroup.controls.SIZE.controls.width.addValidators(this.regexValidator);
        }

        // reset height and width control
        formGroup.controls.SIZE.controls.scaleType.valueChanges
            .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                formGroup.controls.SIZE.controls.height.reset();
                formGroup.controls.SIZE.controls.width.reset();

                if (formGroup.controls.SIZE.controls.scaleType?.value === EScaleTypes.SCALE) {
                    formGroup.controls.SIZE.controls.height.addValidators(this.regexValidator);
                    formGroup.controls.SIZE.controls.width.addValidators(this.regexValidator);
                } else {
                    formGroup.controls.SIZE.controls.height.removeValidators(this.regexValidator);
                    formGroup.controls.SIZE.controls.width.removeValidators(this.regexValidator);
                }

                formGroup.controls.SIZE.updateValueAndValidity();
            });

        this.customPackagesFormArray.push(formGroup);
    }

    public deleteCustomPackage(index: number): void {
        this.customPackagesFormArray.removeAt(index);
    }
}
