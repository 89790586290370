import {Component, EventEmitter, Inject, Input, Optional, Output} from '@angular/core';
import {WorkflowConfigurationModel} from '../../../../../../../models/api/workflow-configuration.model';
import {WorkflowConfigurationComponentModel} from '../../../../../../../models/api/workflow-configuration-component.model';
import {EPublicationJobType, IArrangePagesJobData} from '../../../../../../../api/services/publications.service';
import {Toaster} from '../../../../../../../classes/toaster.class';
import {ARPagedResponseDataModel} from '@relayter/core';
import {PublicationItemModel} from '../../../../../../../models/api/publication-item.model';
import {AppConstants} from '../../../../../../../app.constants';
import {
    EWorkflowConfigurationActionName,
    EWorkflowConfigurationActionType,
    WorkflowConfigurationActionModel
} from '../../../../../../../models/api/workflow-configuration-action.model';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {EColumnDataType, ITableColumn, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {EPublicationDisplayProperties} from '../../../../../../../pipes/publication-item-display.pipe';
import {MonitoredJobsService} from '../../../../../../../api/services/monitored-updates/monitored-jobs.service';
import {CustomWorkflowBaseComponent} from '../../custom-workflow-base.component';
import {IWorkflowModalData} from '../../../../../../../models/interfaces/workflow-modal-data.interface';
import {PublicationItemSelectionService} from '../../custom-workflow-item-selection/publication-item-selection.service';
import {EPublicationMediaProperties} from '../../../../../../../pipes/publication-item-media.pipe';
import {EJobStatus, JobModel} from '../../../../../../../models/api/job.model';
import {TableSortOptions} from '../../../../../../../api/table-sort-options';
import {NewCursor} from '../../../../../../../api/new-api-cursor';

enum EMoveType {
    InsertBefore = 'Insert before',
    InsertAfter = 'Insert after',
    Swap = 'Swap'
}

enum EComponentActions {
    ArrangePages = 'ARRANGE_PAGES'
}

interface ISwappingForm {
    item: FormControl<string>;
    where: FormControl<string>;
    moveType: FormControl<string>;
}

@Component({
    selector: 'rl-custom-workflow-overview-items-component',
    templateUrl: './custom-workflow-overview-items.component.html',
    styleUrls: ['./custom-workflow-overview-items.component.scss'],
    standalone: false
})
export class CustomWorkflowOverviewItemsComponent extends CustomWorkflowBaseComponent {
    protected readonly EPublicationMediaProperties = EPublicationMediaProperties;
    protected readonly Math = Math;

    private cursor: NewCursor;

    public readonly MIN_CARD_SIZE: number = 120;
    public readonly DEFAULT_CARD_SIZE: number = 180;
    public readonly MAX_CARD_SIZE: number = 426;
    public readonly ZOOM_STEP: number = 31;
    public publicationItems: PublicationItemModel[] = [];
    public pageSize = AppConstants.PAGE_SIZE_MAX;
    public hasNext = false;
    public total = 0;
    public isLoading = false;
    public tableSortOptions = new TableSortOptions([{
        title: 'Date created',
        key: '_id',
        sortProperty: '_id',
        dataType: EColumnDataType.STRING,
        sortDuplicates: false
    }] as ITableColumn[]);
    public publicationItemSubscription: Subscription;
    public arrangePagesJobSubscription: Subscription;
    public swapping: boolean;
    public moveTypeControl = new FormControl<string>(EMoveType.InsertBefore);
    public formGroup = new FormGroup<ISwappingForm>({
        item: new FormControl<string>(null),
        where: new FormControl<string>(null),
        moveType: new FormControl<string>(EMoveType.InsertBefore)
    });
    public moveTypes: string[] = Object.values(EMoveType);
    public arrangePagesAction: WorkflowConfigurationActionModel;
    public EPublicationDisplayProperties = EPublicationDisplayProperties;
    public originalPageNumbers: Map<string, number> = new Map();
    public itemTransitionActions: WorkflowConfigurationActionModel[];

    // mat-slider related properties
    private _sliderValue: number;
    @Output() sliderValueChange = new EventEmitter<number>();

    @Input()
    public set sliderValue(value: number) {
        this._sliderValue = value;
        this.sliderValueChange.emit(value);
    }

    public get sliderValue() {
        return this._sliderValue;
    }

    constructor(private publicationItemSelectionService: PublicationItemSelectionService,
                private monitoredJobsService: MonitoredJobsService,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowModalData) {

        super(undefined, modalData);
    }

    public setupData(): void {
        this.cursor = new NewCursor(this.tableSortOptions);
        if (this.publication.workflowConfiguration.sequentialNumbering) {
            this.cursor = null; // For the first request we need total items
            this.tableSortOptions.columns = [{
                key: 'firstPageNumber',
                sortProperty: 'firstPageNumber',
                dataType: EColumnDataType.NUMBER,
                sortDuplicates: false
            }] as ITableColumn[];
        }

        this.sliderValue = this.DEFAULT_CARD_SIZE; // start with the medium card size.

        this.arrangePagesAction = this.findActionByName(EComponentActions.ArrangePages);
        this.refreshData();
        // Reset after init
        this.publicationItemSelectionService.itemsChanged = false;
    }

    public refreshData(): void {
        this.publicationItemSelectionService.itemsChanged = true;
        this.cursor?.reset();
        this.publicationItems = [];
        this.getPublicationItems();
    }

    /**
     * Get (next) publication items from API
     */
    public getPublicationItems(): void {
        if (this.publicationItemSubscription) {
            this.publicationItemSubscription.unsubscribe();
        }

        this.isLoading = true;
        this.publicationItemSubscription = this.publicationItemsApiService.getItemsForPublication(
            this.publication._id,
            this.step?._id,
            this.activeFilters,
            this.pageSize,
            0,
            this.cursor,
            this.tableSortOptions,
            this.publication.workflowConfiguration.sequentialNumbering && !this.cursor) // Only the first request to get total items
            .pipe(finalize(() => this.isLoading = false), takeUntil(this.onDestroySubject))
            .subscribe({
                next: (res: ARPagedResponseDataModel<PublicationItemModel>) => {
                    // We get the total number of items from the first call for PRINT. We need the total for the swap dropdowns
                    this.total = res.total ? res.total : this.total;

                    this.hasNext = !!res.hasNext || (!!res.total && res.total > this.pageSize);
                    this.publicationItems = this.publicationItems.concat(res.items);

                    if (this.publication.workflowConfiguration.sequentialNumbering) {
                        this.originalPageNumbers = new Map([...this.originalPageNumbers, ...new Map(res.items
                            .filter(item => !this.originalPageNumbers.has(item._id))
                            .map((item: PublicationItemModel) => {
                                return [item._id, item.firstPageNumber];
                            }))]);
                        this.publicationItems.forEach((item) => {
                            item.originalPageNumber = this.originalPageNumbers.get(item._id);
                            // temporary solution for items which needs to have the correct page number
                            item.getTitle = () => `Page ${item.formattedPageNumbers}`;
                        });
                    }

                    if (this.hasNext) {
                        if (!this.cursor) this.cursor = new NewCursor(this.tableSortOptions);
                        this.cursor.updateCursor(this.publicationItems[this.publicationItems.length - 1]);
                    }

                    this.itemTransitionActions = {
                        ...this.itemTransitionActions,
                        ...this.getAllowedTransitionActionsForItems(res.items, this.workflowConfiguration, this.component)
                    };
                },
                error: Toaster.handleApiError
            });
    }

    public onRearrangeClicked(): void {
        const movedItem = this.formGroup.value.item;
        const toItem = this.formGroup.value.where;

        const rearrangedItems = [...this.publicationItems.map(item => item._id)];

        const fromIndex = rearrangedItems.indexOf(movedItem);
        let toIndex = rearrangedItems.indexOf(toItem);
        switch (this.formGroup.value.moveType) {
            case EMoveType.InsertBefore:
                rearrangedItems.splice(fromIndex, 1);
                toIndex = rearrangedItems.indexOf(toItem);
                rearrangedItems.splice(toIndex, 0, movedItem);
                break;
            case EMoveType.InsertAfter:
                rearrangedItems.splice(fromIndex, 1);
                toIndex = rearrangedItems.indexOf(toItem) + 1;
                rearrangedItems.splice(toIndex, 0, movedItem);
                break;
            case EMoveType.Swap:
                rearrangedItems[toIndex] = movedItem;
                rearrangedItems[fromIndex] = toItem;
                break;
        }

        const jobData = {
            publicationId: this.publication._id,
            publicationItems: rearrangedItems
        } as IArrangePagesJobData;

        this.publicationsService
            .postJob(EPublicationJobType.ARRANGE_PAGES, jobData)
            .subscribe({
                next: (job) => {
                    if (this.arrangePagesJobSubscription) {
                        this.arrangePagesJobSubscription.unsubscribe();
                    }

                    this.arrangePagesJobSubscription = this.monitoredJobsService.getItemMonitor(job._id)
                        .subscribe((jobModel: JobModel) => {
                            if (jobModel.status === EJobStatus.DONE) {
                                this.resetForm();
                                this.refreshData();
                            }
                        });
                },
                error: Toaster.handleApiError
            });
    }

    public openArrangeForm(item: PublicationItemModel): void {
        this.swapping = true;
        this.formGroup.patchValue({item: item._id});
    }

    private resetForm(): void {
        this.formGroup.reset({moveType: this.formGroup.value.moveType});
        this.formGroup.markAsPristine();
    }

    public closeArrangeForm(): void {
        this.swapping = false;
        this.resetForm();
    }

    private getAllowedTransitionActionsForItems(items: PublicationItemModel[],
                                                workflowConfiguration: WorkflowConfigurationModel,
                                                component: WorkflowConfigurationComponentModel): Record<string, any> {
        const result = {};
        for (const item of items) {
            result[item._id] = this.getAllowedTransitionActionsForItem(item, workflowConfiguration, component);
        }
        return result;
    }

    private getAllowedTransitionActionsForItem(item: PublicationItemModel,
                                               workflowConfiguration: WorkflowConfigurationModel,
                                               component: WorkflowConfigurationComponentModel): WorkflowConfigurationActionModel[] {
        // when the item is being created, it doesn't have step attached
        const allowedActions = [];
        const itemStep = item.step?._id;
        for (const action of component.actions) {
            let isAllowed = false;
            if (this.userIsAllowedToPipe.transform(action.permissions) &&
                (action.type !== EWorkflowConfigurationActionType.TRANSITION_TRIGGER ||
                    workflowConfiguration.transitions.find((transition) =>
                        transition._id === action.transition && transition.from === itemStep))) {
                isAllowed = true;
            }
            if (isAllowed && action !== this.arrangePagesAction) {
                // Not all workflow actions have set the icon, temporary solution to add the trash icon
                // this can be removed later when we have the workflow editor to add the action icon
                if (action.name === 'DELETE_PUBLICATION_ITEMS') {
                    action.icon = 'nucicon_trash_fill';
                }
                allowedActions.push(action);
            }
        }
        return allowedActions;
    }

    public handleAction(action: WorkflowConfigurationActionModel, tableItems: PublicationItemModel[]): void {
        if (action.name === EWorkflowConfigurationActionName.ARRANGE_PAGES) {
            this.openArrangeForm(tableItems[0]);
        } else {
            super.handleAction(action, tableItems);
        }
    }

}
