import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AppConstants} from '../../../app.constants';
import {Toaster} from '../../../classes/toaster.class';

// Services
import {PublicationsService} from '../../../api/services/publications.service';
import {CampaignService} from '../../../api/services/campaigns.service';
import {ProjectsApiService} from '../../../api/services/projects.api.service';

// Models
import {PublicationModel} from '../../../models/api/publication.model';
import {PublicationItemModel} from '../../../models/api/publication-item.model';
import {CampaignModel} from '../../../models/api/campaign.model';
import {ProjectModel} from '../../../models/api/project.model';

export const campaignResolver: ResolveFn<CampaignModel> =
    (route: ActivatedRouteSnapshot) => {
        const router = inject(Router);
        return inject(CampaignService).getCampaignById(route.paramMap.get('campaign_id'))
            .pipe(
                catchError((error) => {
                    router.navigate([AppConstants.CAMPAIGN_PATH]);
                    Toaster.handleApiError(error);
                    return of(null);
                }));
    };

export const publicationResolver: ResolveFn<PublicationModel> =
    (route: ActivatedRouteSnapshot): Observable<PublicationModel> => {
        const router = inject(Router);
        const campaignId = route.parent.paramMap.get('campaign_id') || route.paramMap.get('campaign_id');
        const publicationId = route.paramMap.get('publication_id');
        return inject(PublicationsService).getPublicationDetails(campaignId, publicationId)
            .pipe(
                catchError((error) => {
                    router.navigate([AppConstants.CAMPAIGN_PATH, campaignId, AppConstants.CONTEXT_URL.PUBLICATIONS]);
                    Toaster.handleApiError(error);
                    return of(null);
                }));
    }

export const publicationItemResolver: ResolveFn<PublicationItemModel> =
    (route: ActivatedRouteSnapshot): Observable<PublicationItemModel> => {
        const publicationItemId = route.paramMap.get('item_id')
        const publicationId = route.paramMap.get('publication_id');
        return inject(PublicationsService).getPublicationItem(publicationId, publicationItemId)
            .pipe(
                catchError((error) => {
                    Toaster.handleApiError(error);
                    return of(null);
                }));
    }

export const projectResolver: ResolveFn<ProjectModel> =
    (route: ActivatedRouteSnapshot) => {
        const router = inject(Router);
        return inject(ProjectsApiService).findOne(route.paramMap.get('project_id'))
            .pipe(
                catchError((error) => {
                    router.navigate([AppConstants.PROJECT_PATH]);
                    Toaster.handleApiError(error);
                    return of(null);
                }));
    };
