import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {BooleanOption, ConditionsConstants, ConditionType, PropertyOperator} from './conditions.constants';
import {RulePropertyModel} from '../../models/api/rule-property.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {startWith} from 'rxjs';
import {EDataFieldTypes} from '../../app.enums';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {ConditionBaseType, ConditionValueType, RuleConditionModel} from '../../models/api/rule-condition.model';
import {VariantModel} from '../../models/api/variant.model';
import {PropertyValueModel} from '../../models/ui/property-value.model';
import {EControlType, PropertyControlComponent} from '../../components/property-control/property-control.component';
import {pairwise} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {PropertyControlValidator} from '../../classes/validators/property-control.validator';
import {PropertyControlOptions} from '../../components/property-control/property-control.options';

export class ConditionForm {
    property: FormControl<PropertyValueModel>;
    operator?: FormControl<string>;
    type?: FormControl<string>;
    value?: FormControl<ConditionValueType>;
}

@Component({
    selector: 'condition-form',
    templateUrl: './condition-form.component.html',
    styleUrls: ['./condition-form.component.scss'],
    standalone: false
})
export class ConditionFormComponent implements OnInit {
    @Input() public formGroup: FormGroup<ConditionForm>;
    @Input() public condition: RuleConditionModel;
    @Input() public ruleProperties: RulePropertyModel[];
    @Input() public variants: VariantModel[];
    @Output() public deleteClicked = new EventEmitter<void>();

    public conditionTypes = ConditionType.TYPES;
    public operators = PropertyOperator.OPERATORS;
    public inputType: string;
    public dropdownItems: IDropdownItem<ConditionBaseType>[];
    public propertyOptions: RulePropertyModel[];

    // Property control settings
    public static readonly PropertyControlOptions = new PropertyControlOptions(true, true, true, false);
    protected readonly propertyControlOptions = ConditionFormComponent.PropertyControlOptions;

    private destroyRef = inject(DestroyRef);

    public EControlType = EControlType;

    public get propertyFormControl() {
        return this.formGroup.controls.property;
    }

    public get operatorFormControl() {
        return this.formGroup.controls.operator;
    }

    public get typeFormControl() {
        return this.formGroup.controls.type;
    }

    public get valueFormControl() {
        return this.formGroup.controls.value;
    }

    public ngOnInit(): void {
        this.propertyOptions = this.ruleProperties;
        this.initForm();
    }

    private initForm(): void {
        // Subscribe to value change of the property to update inputType
        this.propertyFormControl.valueChanges
            .pipe(
                startWith(this.propertyFormControl.value),
                pairwise(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(([prevProperty, property]: PropertyValueModel[]) => {
                if (prevProperty === property) return;

                // When changing to enum
                const isDropDownValue = property?.dataType.type === EDataFieldTypes.ENUM ||
                    property?.dataType.type === EDataFieldTypes.BOOLEAN;
                if (PropertyOperator.getByValue(this.operatorFormControl.value) !== PropertyOperator.LENGTH &&
                    (isDropDownValue || (this.inputType === 'dropdown' && !isDropDownValue))) {
                    this.valueFormControl.patchValue(null);
                }

                this.setOperators();
                this.setTypes();
                this.setInputType();
            });

        // Subscribe to value change of the property to update inputType
        this.operatorFormControl.valueChanges
            .pipe(
                startWith(this.operatorFormControl.value),
                pairwise(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(([prevOperator, operator]: string[]) => {
                if (prevOperator === operator) return;

                // Reset value if no longer compatible
                let value = this.valueFormControl.value;
                let type = this.typeFormControl.value;
                switch (PropertyOperator.getByValue(operator)) {
                    case PropertyOperator.LENGTH:
                        value = parseInt(value as string, 10);
                        value = isNaN(value) ? null : value;
                        break;
                    case PropertyOperator.EVERY:
                    case PropertyOperator.SOME:
                        const property = this.propertyFormControl.value;
                        if (property?.dataType?.type !== EDataFieldTypes.ENUM) {
                            value = value?.toString();
                        } else if (!(value instanceof DropdownItem)) {
                            value = null;
                        }
                        break;
                    case PropertyOperator.EXISTS:
                    case PropertyOperator.NOT_EXISTS:
                        value = null;
                        type = null;
                        break;
                }
                this.valueFormControl.patchValue(value);
                this.typeFormControl.patchValue(type);
                this.setTypes();
                if (!this.conditionTypes.find(conditionType => this.typeFormControl.value === conditionType.getValue())) {
                    this.typeFormControl.patchValue(null);
                }
                this.setInputType();
            });

        // Subscribe to value change of the type to update and enable/disable inputType
        this.typeFormControl.valueChanges
            .pipe(
                startWith(this.typeFormControl.value),
                pairwise(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(([prevType, type]: string[]) => {
                if (prevType === type) return;

                if (ConditionType.getByValue(type)?.valueRequired) {
                    this.valueFormControl.enable();
                } else {
                    this.valueFormControl.patchValue(null);
                    this.valueFormControl.disable();
                }

                this.setInputType();
            });

        this.setOperators();
        this.setTypes();
        this.setInputType();
    }

    public setDropdownItems(dataType: string, enumList?: string[]): void {
        switch (dataType) {
            case EDataFieldTypes.ENUM:
                this.dropdownItems = enumList.map((item) => new DropdownItem(item, item));
                break;
            case EDataFieldTypes.BOOLEAN:
                this.dropdownItems = BooleanOption.OPTIONS;
                break;
            default:
                this.dropdownItems = [];
                break;
        }
    }

    /**
     * Set possible operators that can be used for selected property and enable/disable operator control
     */
    public setOperators(): void {
        this.operators = PropertyOperator.getOperatorsForProperty(this.propertyFormControl.value);
        if (this.operators.length) {
            this.operatorFormControl.enable();
        } else {
            this.operatorFormControl.patchValue(null);
            this.operatorFormControl.disable();
        }
    }

    /**
     * Set possible typeOperators that can be used for selected property dataType
     */
    public setTypes(): void {
        let property: string;

        switch (PropertyOperator.getByValue(this.operatorFormControl?.value)) {
            case PropertyOperator.LENGTH:
                property = ConditionsConstants.DATA_TYPE_LENGTH;
                break;
            case PropertyOperator.EXISTS:
            case PropertyOperator.NOT_EXISTS:
                property = null;
                break;
            case PropertyOperator.EVERY:
            case PropertyOperator.SOME:
                property = this.propertyFormControl.value?.dataType.type;
                break;
            default:
                if (!this.propertyFormControl.value?.isArray) {
                    property = this.propertyFormControl.value?.dataType.type;
                }
                break;
        }

        this.conditionTypes = ConditionType.getTypesForProperty(property);
        // No conditions, no value
        if (this.conditionTypes.length === 0) {
            this.valueFormControl.patchValue(null);
        }
    }

    /**
     * Set input type of the value fields based on the property datatype, operator and condition type
     */
    public setInputType(): void {
        const property = this.propertyFormControl.value;
        const operator = PropertyOperator.getByValue(this.operatorFormControl.value);
        const type = ConditionType.getByValue(this.typeFormControl.value);

        // Reset input type if selected type is not selectable
        if (!this.conditionTypes.find(conditionType => conditionType === type)) {
            this.inputType = null;
            this.updateValueValidators();
            return;
        }

        switch (type) {
            case ConditionType.LEADING_LENGTH:
            case ConditionType.LEADING_LENGTH_GREATER_THAN:
            case ConditionType.LENGTH_GREATER_THAN:
            case ConditionType.LENGTH:
                this.inputType = EDataFieldTypes.NUMBER;
                break;
            case ConditionType.LOWER_OR_EQUAL:
            case ConditionType.LOWER_THAN:
            case ConditionType.GREATER_OR_EQUAL:
            case ConditionType.GREATER_THAN:
                this.inputType = property?.dataType?.type === EDataFieldTypes.DATE ? EDataFieldTypes.DATE : EDataFieldTypes.NUMBER;
                break;
            case ConditionType.NOT_EQUALS:
            case ConditionType.EQUALS:
                if (operator === PropertyOperator.LENGTH) {
                    this.inputType = EDataFieldTypes.NUMBER;
                } else {
                    const propertyDataType = property?.dataType?.type as string;
                    if (propertyDataType === EDataFieldTypes.ENUM) {
                        this.inputType = 'dropdown';
                        this.setDropdownItems(EDataFieldTypes.ENUM, property.dataType.enumeration?.items);
                    } else if (propertyDataType === EDataFieldTypes.BOOLEAN) {
                        this.inputType = 'dropdown';
                        this.setDropdownItems(EDataFieldTypes.BOOLEAN);
                    } else if (propertyDataType === EDataFieldTypes.LIST) {
                        this.inputType = operator ? EDataFieldTypes.STRING : null;
                    } else if (propertyDataType === ConditionsConstants.DATA_TYPE_LENGTH) {
                        // Normally, propertyDataType will only be length for array operator length
                        this.inputType = EDataFieldTypes.NUMBER;
                    } else if (propertyDataType === EDataFieldTypes.OBJECT_ID) {
                        this.inputType = EDataFieldTypes.STRING;
                    } else {
                        this.inputType = propertyDataType;
                    }
                }
                break;
            case ConditionType.NOT_STARTS_WITH:
            case ConditionType.STARTS_WITH:
                this.inputType = EDataFieldTypes.STRING;
                break;
            case ConditionType.EXISTS:
            case ConditionType.NOT_EXISTS:
            default:
                this.inputType = null;
                break;
        }

        this.updateValueValidators();
    }

    /**
     * Add/remove required validator and enable/disable value control
     */
    private updateValueValidators(): void {
        // Update validators, based on the inputType
        if (this.inputType) {
            this.valueFormControl.addValidators(Validators.required);

            // optional validator for number field, a positive number is required by default
            this.inputType === EDataFieldTypes.NUMBER
                ? this.valueFormControl.addValidators(Validators.min(0))
                : this.valueFormControl.removeValidators(Validators.min(0));
        } else {
            this.valueFormControl.clearValidators();
        }
        // Apply the new validators
        this.valueFormControl.updateValueAndValidity();
    }

    public static createForm(): FormGroup<ConditionForm> {
        const propertyControl = new FormControl<PropertyValueModel>(null, [
            Validators.required,
            PropertyControlValidator()
        ]);
        const operatorControl = new FormControl<string>(null, [(control: AbstractControl) => {
            if (propertyControl.value?.isArray && !control.value) {
                return {valueRequired: 'Operator is required for this property'};
            }

            return null;
        }]);
        const typeControl = new FormControl<string>(null, [(control: AbstractControl) => {
            const operatorControlValue = PropertyOperator.getByValue(operatorControl.value);
            if (operatorControlValue?.typeRequired && !control.value) {
                return {valueRequired: 'Type is required for this operator'};
            }

            if (propertyControl.value && (!propertyControl.value?.isArray || propertyControl.value?.arrayIndexSelected) && !control.value) {
                return {valueRequired: 'Type is required for this property'};
            }

            return null;
        }]);
        // If a control is disabled, it is skipped in the validation
        const valueControl = new FormControl<ConditionValueType>('',
            [Validators.required, (control: AbstractControl) => {
                if (ConditionType.getByValue(typeControl.value)?.valueRequired && (control.value === undefined || control.value === null ||
                    control.value === '')) {
                    return {valueRequired: 'Value is required for this type'};
                }

                const operatorControlValue = PropertyOperator.getByValue(operatorControl.value);

                if (operatorControlValue === PropertyOperator.LENGTH && control.value < 0) {
                    return {valueRequired: 'Minimal value for length is 0'};
                }
                if (operatorControlValue === PropertyOperator.LENGTH && control.value < 0) {
                    return {valueRequired: 'Minimal value for length is 0'};
                }
                if (operatorControlValue === PropertyOperator.LENGTH && !Number.isInteger(control.value)) {
                    return {valueRequired: 'Value for length has to be an integer number'};
                }

                return null;
            }
            ]);

        const formGroup = new FormGroup<ConditionForm>({
            property: propertyControl,
            operator: operatorControl,
            type: typeControl,
            value: valueControl
        });
        formGroup.setValidators(Validators.required);

        return formGroup;
    }

    public static getPatchValue(condition: RuleConditionModel, variants: VariantModel[], ruleProperties: RulePropertyModel[]): Record<string, any> {
        const property = PropertyControlComponent.getPropertyValueModel(condition?.property, ruleProperties, variants,
            ConditionFormComponent.PropertyControlOptions);
        const operator = condition.operator;
        const type = condition.type;
        const value = ConditionFormComponent.getConditionValue(condition, property?.dataType.type);

        return {property, operator, type, value};
    }

    /**
     * Get condition value and put it in a dropdown item if needed.
     */
    private static getConditionValue(condition: RuleConditionModel, dataType?: EDataFieldTypes): ConditionValueType {
        switch (ConditionType.getByValue(condition?.type)) {
            case ConditionType.LEADING_LENGTH:
            case ConditionType.LEADING_LENGTH_GREATER_THAN:
            case ConditionType.LENGTH_GREATER_THAN:
            case ConditionType.LENGTH:
            case ConditionType.LOWER_OR_EQUAL:
            case ConditionType.LOWER_THAN:
            case ConditionType.GREATER_OR_EQUAL:
            case ConditionType.GREATER_THAN:
            case ConditionType.NOT_STARTS_WITH:
            case ConditionType.STARTS_WITH:
                return condition.value;
            case ConditionType.NOT_EQUALS:
            case ConditionType.EQUALS:
                if (dataType === EDataFieldTypes.BOOLEAN) {
                    return !!condition.value;
                } else {
                    return condition.value;
                }
            case ConditionType.EXISTS:
            case ConditionType.NOT_EXISTS:
                break;
        }
    }
}
