import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {FormArray, FormControl, FormControlStatus, FormGroup} from '@angular/forms';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {distinctUntilChanged, finalize} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {WorkflowAutomationsService} from '../../api/services/workflow-automations.service';
import {Toaster} from '../../classes/toaster.class';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {TabBarItemModel} from '../../models/ui/tab-bar-item.model';
import {TemplateModel} from '../../models/api/template.model';
import {RulePropertyModel} from '../../models/api/rule-property.model';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {
    WorkflowAutomationModel,
    WorkflowAutomationRuleBodyModel,
    WorkflowAutomationRuleModel
} from '../../models/api/workflow-automation.model';
import {RuleConditionModel} from '../../models/api/rule-condition.model';
import {AppConstants} from '../../app.constants';
import {EPropertyContext, PropertyService} from '../../api/services/property.service';
import {EDataFieldTypes, EFormStatus} from '../../app.enums';
import {VariantService} from '../../api/services/variant.service';
import {VariantModel} from '../../models/api/variant.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ConditionForm} from '../rule-form/condition-group-form/condition-group-form.component';
import {TemplatesApiService} from '../../api/services/templates.api.service';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export interface IWorkflowAutomationRuleFormData {
    workflowAutomation: WorkflowAutomationModel;
    rule: WorkflowAutomationRuleModel;
}

interface AutomationRuleForm {
    name: FormControl<string>;
    template: FormControl<string>;
    createSinglePublicationItem: FormControl<AdvancedRadioButtonOption>;
    alwaysCreatePublicationItem: FormControl<boolean>;
    conditions?: FormArray<FormGroup<ConditionForm>>;
}

interface AdvancedRadioButtonOption {
    label: string,
    sublabel: string,
    image: string,
    value: boolean
}

@Component({
    selector: 'workflow-automation-rule-form-component',
    templateUrl: 'workflow-automation-rule-form.component.html',
    styleUrls: ['workflow-automation-rule-form.component.scss'],
    standalone: false
})

export class WorkflowAutomationRuleFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    private confirmButton: ButtonConfig;
    private onConfirmClicked: () => void = this.nextAction;

    public formGroup: FormGroup<AutomationRuleForm>;

    // data
    public rule: WorkflowAutomationRuleModel;
    public templates: TemplateModel[] = [];
    private templateOffset = 0;
    private templateSearch: string;
    public totalTemplates: number;
    public ruleProperties: RulePropertyModel[] = [];
    public variants: VariantModel[] = [];
    private selectedTemplate: TemplateModel;
    public defaultPageSize = AppConstants.PAGE_SIZE_DEFAULT;
    private templateSubscription: Subscription;
    public createSinglePublicationItemOptions: AdvancedRadioButtonOption[] = [
        {
            label: 'Create multiple publication items',
            sublabel: 'Create a publication item for every briefing item that matches this condition',
            value: false,
            image: '/assets/images/automation_rule_multiple.svg'
        },
        {
            label: 'Create single publication item',
            sublabel: 'Create one publication item with this template, and assign all briefing items that match the conditions',
            value: true,
            image: '/assets/images/automation_rule_single.svg'
        }
    ]

    // tab bar related
    public TAB_RULE_DESCRIPTION = 0;
    public TAB_CONDITIONS = 1;
    public ruleDescriptionTab = new TabBarItemModel('Rule description', this.TAB_RULE_DESCRIPTION);
    public conditionsTab = new TabBarItemModel('Conditions', this.TAB_CONDITIONS);
    public tabBarItems: TabBarItemModel[] = [this.ruleDescriptionTab, this.conditionsTab];

    private _selectedTab = this.tabBarItems[this.TAB_RULE_DESCRIPTION];
    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabBarItems.find((t) => t.title === tab.title).index;
            this._selectedTab = tab;
            this._selectedTab.index = index;
            this.setConfirmButton();
            this.updateButtonStatus(this.formGroup.status);
        }
    }

    constructor(private fullModalService: FullModalService,
                private workflowAutomationsService: WorkflowAutomationsService,
                private templatesApiService: TemplatesApiService,
                private propertyService: PropertyService,
                private variantService: VariantService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowAutomationRuleFormData) {
        this.rule = this.modalData.rule;
    }

    public ngOnInit(): void {
        if (this.rule) {
            this.selectedTemplate = this.rule.template;
            this.templates = this.selectedTemplate ? [this.selectedTemplate] : [];
        }
        this.getTemplates();
        this.setupFormGroup();
        this.initModalButtons();
        this.listenToFormChanges();
        this.getRuleProperties();
        this.getVariants();
    }

    private setupFormGroup(): void {
        this.formGroup = new FormGroup<AutomationRuleForm>({
            name: new FormControl(this.rule?.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            template: new FormControl(this.selectedTemplate?._id),
            // default is false, which generates multiple items
            createSinglePublicationItem: new FormControl(
                    this.createSinglePublicationItemOptions
                        .find((option) => option.value === !!this.rule?.createSinglePublicationItem),
                RLValidatorConstants.VALIDATOR_SETS.REQUIRED
            ),
            alwaysCreatePublicationItem: new FormControl(!!this.rule?.alwaysCreatePublicationItem)
        });
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.confirmButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Next', null, false, false);

        const cancelAction = new FullModalActionModel(cancelButton);
        const confirmAction = new FullModalActionModel(this.confirmButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        confirmAction.observable.subscribe(() => this.onConfirmClicked());
        this.fullModalService.setModalActions([cancelAction, confirmAction]);
    }

    private listenToFormChanges(): void {
        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status: FormControlStatus) => this.updateButtonStatus(status));
    }

    public getTemplates(): void {
        if (this.templateSubscription) this.templateSubscription.unsubscribe();
        this.templateSubscription =
            this.templatesApiService.getTemplates(
                this.defaultPageSize,
                this.templateOffset,
                null,
                null,
                this.templateSearch
            )
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    // only add unique value here
                    this.templates = this.templates.concat(result.items.filter((item) => item._id !== this.selectedTemplate?._id));
                    this.totalTemplates = result.total;
                },
                error: Toaster.handleApiError
            });
    }

    private getRuleProperties(): void {
        this.propertyService.getProperties(EPropertyContext.WORKFLOW_AUTOMATION)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => this.ruleProperties = result.items,
                error: Toaster.handleApiError
            });
    }

    private getVariants(): void {
        this.variantService.getVariants()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => this.variants = result.items,
                error: Toaster.handleApiError
            });
    }

    private updateButtonStatus(status: FormControlStatus): void {
        this.confirmButton.disabled = this.selectedTab.index === this.TAB_CONDITIONS && status !== EFormStatus.VALID;
    }

    private nextAction(): void {
        this.selectedTab = this.tabBarItems.find((tab) => tab.index === this.selectedTab.index + 1);
    }

    private setConfirmButton(): void {
        switch (this._selectedTab.index) {
            case this.TAB_RULE_DESCRIPTION:
                this.confirmButton.text = 'Next';
                this.onConfirmClicked = this.nextAction;
                return;
            case this.TAB_CONDITIONS:
                this.confirmButton.text = 'Save';
                this.onConfirmClicked = this.saveRule;
                break;
        }
    }

    private saveRule(): void {
        this.confirmButton.loading = true;
        const conditions = this.formGroup.value.conditions.map((condition) => {
            const conditionProperty = condition.property;
            const property = conditionProperty.path;
            const operator = condition.operator;
            const type = condition.type;
            const value = condition.value instanceof DropdownItem ? condition.value.getValue() : condition.value;
            const dataType = conditionProperty.dataType?.type === EDataFieldTypes.DATE ? EDataFieldTypes.DATE : null;

            return new RuleConditionModel(property,
                type,
                null,
                value,
                null,
                operator,
                dataType);
        });
        const body = new WorkflowAutomationRuleBodyModel(
            this.formGroup.value.name,
            this.formGroup.value.template,
            conditions,
            this.formGroup.value.createSinglePublicationItem.value,
            this.formGroup.value.alwaysCreatePublicationItem
        );

        const observable = this.rule
            ? this.workflowAutomationsService.updateAutomationRule(this.modalData.workflowAutomation._id, this.rule._id, body)
            : this.workflowAutomationsService.createAutomationRule(this.modalData.workflowAutomation._id, body);

        observable
            .pipe(
                finalize(() => this.confirmButton.loading = false),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: (result) => {
                    this.fullModalService.close(result);
                    this.rule ? Toaster.success('Workflow automation rule updated successfully') :
                        Toaster.success('Workflow automation rule created successfully');
                },
                error: Toaster.handleApiError
            });
    }

    public searchTemplates(event: IDropdownRequestDataEvent): void {
        if (event.reset) {
            this.templates = [];
        }

        this.templateOffset = event.offset;
        this.templateSearch = event.search;

        this.getTemplates();
    }
}
