import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {ETabFormGroup} from '../package-rule-form/package-rule-form.component';
import {RulePropertyModel} from '../../models/api/rule-property.model';
import {RuleConditionModel} from '../../models/api/rule-condition.model';
import {VariantModel} from '../../models/api/variant.model';
import {ConditionForm, ConditionFormComponent} from '../conditions-form/condition-form.component';

@Component({
    selector: 'rule-conditions-form-component',
    templateUrl: 'rule-conditions-form.component.html',
    styleUrls: ['rule-conditions-form.component.scss'],
    standalone: false
})

export class RuleConditionsFormComponent implements OnInit {
    public static readonly FORM_GROUP_NAME = ETabFormGroup.PACKAGE_RULE_CONDITIONS;

    @Input() public form: FormGroup;
    @Input() public formGroupName?: string = RuleConditionsFormComponent.FORM_GROUP_NAME;
    @Input() public ruleConditions: RuleConditionModel[] = [];

    @Input() public ruleProperties: RulePropertyModel[];
    @Input() public variants: VariantModel[];
    @Input() public imageName: string;

    get conditionsFormArray(): FormArray<FormGroup<ConditionForm>> {
        return this.form.get(this.formGroupName) as FormArray<FormGroup<ConditionForm>>;
    }

    public ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        if (!this.form.contains(this.formGroupName)) {
            const controls = [];
            const patchValue = [];
            if (this.ruleConditions?.length) {
                this.ruleConditions.forEach((condition) => {
                    controls.push(ConditionFormComponent.createForm());
                    patchValue.push(ConditionFormComponent.getPatchValue(condition, this.variants, this.ruleProperties))
                });
            }
            this.form.addControl(this.formGroupName, new FormArray<FormGroup<ConditionForm>>(controls));
            this.form.patchValue({ [this.formGroupName]: patchValue });
        } else {
            this.ruleConditions = Object.values(this.form.value[this.formGroupName]);
        }
    }

    public onDeleteCondition(index: number): void {
        if (this.conditionsFormArray.controls[index]) {
            this.ruleConditions.splice(index, 1);
            this.conditionsFormArray.removeAt(index);
        }

    }

    public onAddConditionClicked(): void {
        // Add field to the form, initial empty value
        this.ruleConditions.push(new RuleConditionModel());
        this.conditionsFormArray.push(ConditionFormComponent.createForm());
    }
}
