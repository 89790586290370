import {
    AfterViewInit,
    ApplicationRef,
    Component,
    DestroyRef,
    effect,
    ElementRef,
    inject,
    input,
    Input,
    OnDestroy,
    Renderer2,
    signal,
    Signal,
    viewChild,
    WritableSignal
} from '@angular/core';
import {DurationMediaPipe, NUCButtonsModule, SeekbarComponent, VideoPlayerComponent, VolumeControlDirective} from '@relayter/rubber-duck';
import {PipesModule} from '../../../../../../../../pipes/pipes.module';
import {PublicationItemModel} from '../../../../../../../../models/api/publication-item.model';
import {
    StickyNotesOverlayComponent, StickyOverlayDisplayOptions
} from '../sticky-notes-overlay/sticky-notes-overlay.component';
import {StickyNotesDataService} from '../../preview-sticky-notes-sidebar/sticky-notes-data.service';
import {NoteTimeRange, StickyNoteModel} from '../../../../../../../../models/api/sticky-note.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CustomWorkflowPreviewDataService} from '../../custom-workflow-preview-data.service';
import {CdkDrag} from '@angular/cdk/drag-drop';
import {StickyNoteTimelineComponent} from './sticky-note-timeline/sticky-note-timeline.component';
import {merge} from 'rxjs';

@Component({
    selector: 'video-sticky-notes-view',
    imports: [
        NUCButtonsModule,
        DurationMediaPipe,
        VolumeControlDirective,
        SeekbarComponent,
        PipesModule,
        StickyNotesOverlayComponent,
        CdkDrag,
        StickyNoteTimelineComponent
    ],
    templateUrl: './video-sticky-notes-view.component.html',
    styleUrl: './video-sticky-notes-view.component.scss'
})
export class VideoStickyNotesViewComponent extends VideoPlayerComponent implements AfterViewInit, OnDestroy {
    @Input() public item: PublicationItemModel;
    public overlay = input<StickyOverlayDisplayOptions>();

    private renderer2: Renderer2 = inject(Renderer2);
    private destroyRef: DestroyRef = inject(DestroyRef);
    private stickyNotesDataService: StickyNotesDataService = inject(StickyNotesDataService);
    private previewDataService: CustomWorkflowPreviewDataService = inject(CustomWorkflowPreviewDataService);
    private sizer: Signal<ElementRef> = viewChild<ElementRef>('sizer');
    private vContainer: Signal<ElementRef> = viewChild<ElementRef>('videoContainer');
    public drag: Signal<CdkDrag> = viewChild<CdkDrag>(CdkDrag);

    private containerSizeObservable: ResizeObserver = new ResizeObserver(() => {
        window.requestAnimationFrame(() => {
            this.calculateMaxWidth();
        });
    });

    public stickyNotes: StickyNoteModel[] = [];
    public visibleNotes: WritableSignal<StickyNoteModel[]> = signal([]);
    public dragging: WritableSignal<boolean> = signal<boolean>(false);

    constructor(private appRef: ApplicationRef) {
        super();
        effect(() => {
            this.updateVisibleNotes(this.currentTime());
        }, {allowSignalWrites: true});
    }

    public metaDataLoaded(): void {
        this.containerSizeObservable.observe(this.vContainer().nativeElement);
        super.metaDataLoaded();
    }

    public ngAfterViewInit() {
        super.ngAfterViewInit();
        this.previewDataService.stickyNotes$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(stickyNotes => {
                this.stickyNotes = stickyNotes;
                this.updateVisibleNotes(this.currentTime() || 0);
            });

        merge(this.stickyNotesDataService.selectedStickyNote$, this.stickyNotesDataService.clickedStickyNote$)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((stickyNote: StickyNoteModel) => {
                if (!stickyNote) return;

                if (stickyNote?.timeRange) {
                    this.seekVideo(stickyNote.timeRange.start);
                }

                this.pauseVideo();
            });
    }

    public ngOnDestroy(): void {
        // Reset clicked sticky note, so it will not jump to it again when publication item is showed
        this.stickyNotesDataService.setClickedStickyNote(null);
        this.containerSizeObservable.disconnect()
    }

    private calculateMaxWidth() {
        const width = (this.vContainer().nativeElement.offsetHeight - 24) * (16 / 9);
        this.renderer2.setStyle(this.sizer().nativeElement, 'max-width', width + 'px');
    }

    private updateVisibleNotes(currentTime: number): void {
        this.visibleNotes.set(this.stickyNotes?.filter((note) => {
            // if no timeRange set show for the whole video
            if (!note.timeRange) {
                return true;
            }
            return note.timeRange.start <= currentTime && note.timeRange.end >= currentTime;
        }))
    }

    public newStickyNote(newStickyNote: StickyNoteModel) {
        if (newStickyNote) {
            newStickyNote.timeRange = new NoteTimeRange(this.currentTime(), this.currentTime() + 2);
        }
        this.stickyNotesDataService.setSelectedStickyNote(newStickyNote);
    }

    /**
     * Start registering panning of the preview, during this action block creation of new notes
     */
    public startDragging(): void {
        this.dragging.set(true);
    }

    /**
     * When panning ends wait 100ms before allowing to create notes to prevent creation of note when you release your mouse.
     */
    public stopDragging(): void {
        setTimeout(() => {
            this.dragging.set(false);
            this.appRef.tick();
        }, 100);
    }

    public playPauseVideo() {
        if (!this.dragging()) {
            super.playPauseVideo();
        }
    }

    public fitToContainer(): void {
        if (this.drag()?.getRootElement()) this.drag().reset();
    }
}
