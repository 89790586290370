import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService} from '@relayter/rubber-duck';
import {RulePropertyModel} from '../../models/api/rule-property.model';
import {DestroyRef, Directive, inject, OnInit} from '@angular/core';
import {
    ConditionType,
    ConditionTypeValue,
    FormatOption,
    PropertyOperator
} from '../../modules/static-content-rulesets/static-content-ruleset.constants';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {ConditionValueType, RuleConditionModel} from '../../models/api/rule-condition.model';
import {ValueModel} from '../../modules/static-content-rulesets/models/api/ruleset-value.model';
import {
    IFormatRulesetItemFormComponentData
} from '../../modules/static-content-rulesets/static-content-ruleset-item-form/static-content-ruleset-item-form.component';
import {EDataCollectionName, EDataFieldTypes, EFormStatus, RULESET_OPERATORS} from '../../app.enums';
import {
    IFormatRulesetAssetItemFormComponentData
} from '../../modules/static-content-rulesets/static-content-ruleset-asset-item-form/static-content-ruleset-asset-item-form.component';
import {StaticContentRulesetAssetItemModel} from '../../modules/static-content-rulesets/models/api/static-content-ruleset-asset-item.model';
import {format as dateFormatter} from 'date-fns-tz';
import {nl as DEFAULT_LOCALE} from 'date-fns/locale';
import {ARLogger} from '@relayter/core';
import {AppConstants} from '../../app.constants';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {DataCollectionService} from '../../api/services/data-collection.service';
import {ConditionGroupsModel} from '../../models/api/condition-groups.model';
import {ConditionGroupModel} from '../../models/api/condition-group.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {ConditionForm, ConditionGroup, ConditionGroups} from './condition-group-form/condition-group-form.component';
import {ValueForm, ValuesFormComponent} from './values-form/values-form.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {PropertyControlComponent} from '../../components/property-control/property-control.component';
import {PropertyValueModel} from '../../models/ui/property-value.model';
import {PropertyControlValidator} from '../../classes/validators/property-control.validator';
import {ConditionsFormComponent} from './conditions-form/conditions-form.component';
import {
    IAnimatedContentRulesetRuleFormComponentData
} from '../../modules/animated-content-rulesets/animated-content-ruleset-rule-form/animated-content-ruleset-rule-form.component';
import {Toaster} from '../../classes/toaster.class';

export enum ERulesetContext {
    ITEMS,
    ASSET_ITEMS
}

export interface IFormatRulesetComponentData {
    context: ERulesetContext;
}

export interface ITagValueSelection {
    tags: string[];
}

@Directive()
export abstract class BaseRulesetItemFormComponent implements OnInit {
    protected readonly destroyRef = inject(DestroyRef);

    public nameControl = new FormControl<string>('', Validators.required);
    public itemControl = new FormControl<ITagValueSelection>(null, Validators.required);
    public conditionGroups = new ConditionGroups([(control: AbstractControl) => {
        const groups = control['groups'] as FormArray<ConditionGroup>;
        for (const group of groups.controls) {
            const rules = group['rules'] as FormArray<FormGroup<ConditionForm>>;
            for (const rule of rules.controls) {
                if (rule.invalid) {
                    return {message: 'All groups need to be valid.'};
                }
            }
        }
        return null;
    }]);

    public valueGroups = new FormArray<FormGroup<ValueForm>>([]);

    private saveButtonConfig: ButtonConfig;

    public ruleProperties: RulePropertyModel[];

    public formGroup: FormGroup;
    public modalData: IFormatRulesetItemFormComponentData | IFormatRulesetAssetItemFormComponentData | IAnimatedContentRulesetRuleFormComponentData;

    public tags: DropdownItem<string>[] = [];
    public DATE_FORMAT = FormatOption.DATE_FORMAT.getValue();
    public TO_STRING = FormatOption.TO_STRING.getValue();

    public static GROUP_OPTIONS: IDropdownItem<RULESET_OPERATORS>[] = [
        new DropdownItem('AND', RULESET_OPERATORS.AND, false, 'nucicon_code-and'),
        new DropdownItem('OR', RULESET_OPERATORS.OR, false, 'nucicon_code-or')];

    public layerOptions: DropdownItem<string>[];
    public layerProperty: string;
    public searchLayer: string;
    public totalLayers: number;

    protected constructor(protected fullModalService: FullModalService,
                          protected dataCollectionService: DataCollectionService) {
    }

    // TODO: Line up DropDownItems
    private static getConditionValue(condition?: RuleConditionModel, dataType?: EDataFieldTypes): ConditionValueType {
        switch (ConditionType.getByValue(condition?.type)) {
            case ConditionType.LEADING_LENGTH:
            case ConditionType.LEADING_LENGTH_GREATER_THAN:
            case ConditionType.LENGTH_GREATER_THAN:
            case ConditionType.LENGTH:
            case ConditionType.LOWER_OR_EQUAL:
            case ConditionType.LOWER_THAN:
            case ConditionType.GREATER_OR_EQUAL:
            case ConditionType.GREATER_THAN:
            case ConditionType.REGEX:
                return condition.value;
            case ConditionType.NOT_EQUALS:
            case ConditionType.EQUALS:
            case ConditionType.INCLUDES:
            case ConditionType.NOT_INCLUDES:
                if (dataType === EDataFieldTypes.BOOLEAN) {
                    return !!condition.value;
                }
                return condition.value;
            case ConditionType.EXISTS:
            case ConditionType.NOT_EXISTS:
                break;
        }
    }

    public ngOnInit(): void {
        this.initButtons();
        this.initForm();
    }

    private initButtons(): void {
        this.saveButtonConfig =
            new ButtonConfig(BUTTON_TYPE.PRIMARY, this.modalData.item ? 'Save' : 'Create', false,
                false, this.formGroup.status !== EFormStatus.VALID);
        const cancelButtonConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const saveAction = new FullModalActionModel(this.saveButtonConfig);
        const cancelAction = new FullModalActionModel(cancelButtonConfig);
        const actions = [
            cancelAction,
            saveAction,
        ];

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveRule());

        this.fullModalService.setModalActions(actions);
    }

    protected initForm(): void {
        this.ruleProperties = this.modalData.ruleProperties;

        if (this.modalData.item) {

            if (!(this.modalData.item instanceof StaticContentRulesetAssetItemModel)) {
                this.modalData.item.values.forEach((value) => {
                    this.addValueGroup(value);
                });
            }
            this.conditionGroups.setOperator(this.modalData.item.conditions.operator);
            this.modalData.item.conditions.groups.forEach((condition) => {
                const conditionGroup = new ConditionGroup(condition.operator);
                for (const rule of condition.rules) {
                    this.addConditionGroup(rule, conditionGroup);
                }
                this.conditionGroups.addGroup(conditionGroup);
            });
        }

        this.saveButtonConfig.disabled = this.formGroup.status !== EFormStatus.VALID;

        this.formGroup.statusChanges.pipe(
            map((status) => status === EFormStatus.VALID),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((valid) => this.saveButtonConfig.disabled = !valid);

        this.itemControl.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((tagValue: ITagValueSelection) => {
            this.tags = tagValue?.tags.map((tag: string) => new DropdownItem<string>(tag, tag)) || [];
            this.valueGroups.controls.forEach((item) => {
                const tagControl = item.controls.tag;
                const tagValue = tagControl.value;

                if (!this.tags.find((tag) => tagValue === tag.getValue())) {
                    tagControl.patchValue(null);
                }
            });
        });
    }

    public searchLayers(searchValue: string): void {
        if (this.searchLayer !== searchValue) {
            this.searchLayer = searchValue;
            this.layerOptions = [];

            this.getLayers();
        }
    }

    public getLayers(offset = 0): void {
        this.dataCollectionService.getDataCollectionValues(EDataCollectionName.STATIC_CONTENT_RULE_SET,
            this.layerProperty, null, AppConstants.PAGE_SIZE_DEFAULT, offset, this.searchLayer)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (results) => {
                    const layers = results.items.map(item =>
                        new DropdownItem<string>(item.title, item.value));
                    this.layerOptions = this.layerOptions?.concat(layers) || layers;
                    this.totalLayers = results.total;
                },
                error: Toaster.handleApiError
            });
    }

    protected getConditions(): ConditionGroupsModel {
        return new ConditionGroupsModel(this.conditionGroups.operator.value,
            this.conditionGroups.groups.controls.map((group: ConditionGroup) => {
                return new ConditionGroupModel(group.operator.value,
                    group.rules.controls.map((rule) => {

                        const property = rule.value.property.path;
                        const operator = rule.value.operator;
                        const type = rule.value.type;
                        const typeValue = rule.value.typeValue;
                        const value = rule.value.value instanceof DropdownItem ?
                            rule.value.value.getValue() : rule.value.value;
                        const valueProperty = rule.value.valueProperty?.path;
                        const lastRuleProperty = rule.value.property;
                        const dataType = lastRuleProperty?.dataType?.type === EDataFieldTypes.DATE ?
                            EDataFieldTypes.DATE : null;

                        return new RuleConditionModel(property,
                            type,
                            typeValue,
                            value,
                            valueProperty,
                            operator,
                            dataType
                        );
                    }));
            }));
    }

    protected getValues(): ValueModel[] {
        return this.valueGroups.value.map((value) => {
            return new ValueModel(value.tag, value.property.path,
                value.format ? value.format : undefined,
                value.formatString ? value.formatString : undefined);
        });
    }

    public addConditionGroup(condition?: RuleConditionModel, parent?: ConditionGroup): void {

        const property = PropertyControlComponent.getPropertyValueModel(condition?.property, this.ruleProperties, [],
            ConditionsFormComponent.PropertyControlOptions);
        const valueProperties = RulePropertyModel.filterPropertiesOnDataType(property?.dataType.type, this.ruleProperties);

        const conditionGroup = new FormGroup<ConditionForm>({
            property: new FormControl<PropertyValueModel>(
                property, [Validators.required, PropertyControlValidator()]),
            operator: new FormControl(condition?.operator),
            type: new FormControl(condition?.type),
            typeValue: new FormControl(condition?.typeValue),
            value: new FormControl(BaseRulesetItemFormComponent.getConditionValue(condition, property?.dataType.type)),
            valueProperty: new FormControl(PropertyControlComponent.getPropertyValueModel(condition?.valueProperty, valueProperties, []),
                PropertyControlValidator()),
        }, [(control: AbstractControl) => {

            if (control.value.property?.isArray && !control.value.property?.arrayIndexSelected && !control.value.operator) {
                return {valueRequired: 'Operator is required for this property'};
            }

            if ((!control.value.property?.isArray || control.value.property?.arrayIndexSelected) && !control.value.type) {
                return {valueRequired: 'Type is required for this property'};
            }

            if (control.value.operator?.typeRequired && !control.value.type) {
                return {valueRequired: 'Type is required for this operator'};
            }

            if (control.value.type?.valueRequired) {
                if ((!control.value.typeValue || control.value.typeValue === ConditionTypeValue.CUSTOM) &&
                    (control.value.value === undefined || control.value.value === null ||
                        control.value.value === '')) {
                    return {valueRequired: 'Value is required for this type'};
                } else if (control.value.typeValue === ConditionTypeValue.PROPERTY && !control.value.valueProperty) {
                    return {valueRequired: 'Value property is required for this type'};
                }

                if (control.value.operator === PropertyOperator.LENGTH && control.value.value < 0) {
                    return {valueRequired: 'Minimal value for length is 0'};
                }
                if (control.value.operator === PropertyOperator.LENGTH && !Number.isInteger(control.value.value)) {
                    return {valueRequired: 'Value for length has to be an integer number'};
                }
            }

            if (control.value.type === ConditionType.REGEX && control.value.type?.valueRequired) {
                try {
                    new RegExp(control.value.value);
                } catch (error) {
                    return {invalidRegex: error.message};
                }
            }
            return null;
        }, Validators.required]);

        parent.addRule(conditionGroup);
    }

    public addValueGroup(value?: ValueModel): void {
        const valueGroup = new FormGroup<ValueForm>({
            tag: new FormControl(null, Validators.required),
            property: new FormControl(null, [Validators.required, PropertyControlValidator()]),
            format: new FormControl(null),
            formatString: new FormControl(null)
        }, [(control: AbstractControl) => {
            const formatValue = control.value.format;
            switch (formatValue) {
                case this.DATE_FORMAT: {
                    // Format string required
                    if (control.value.formatString === undefined || control.value.formatString === null || control.value.formatString === '') {
                        return {valueRequired: 'Format string is required for the date format'};
                    }

                    // Valid format string
                    try {
                        const options = {
                            timeZone: AppConstants.DEFAULT_TIMEZONE,
                            locale: DEFAULT_LOCALE,
                            useAdditionalWeekYearTokens: true,
                            useAdditionalDayOfYearTokens: true
                        };
                        dateFormatter(new Date(), control.value.formatString, options);
                    } catch (error) {
                        ARLogger.error(error.message);
                        return {invalidFormatString: 'Format string is not valid'};
                    }
                    break;
                }
                case this.TO_STRING: {
                    // Separator required
                    if (control.value.formatString === undefined || control.value.formatString === null || control.value.formatString === '') {
                        return {valueRequired: 'Separator is required for the to string format'};
                    }
                    break;
                }
            }

            if (control.value.property && control.value.property[control.value.property.length - 2]?.isArray &&
                !control.value.property[control.value.property.length - 1] && formatValue !== this.TO_STRING) {
                return {valueRequired: 'For array properties without selected index the \'To string\' formatter is required'};
            }

            return null;
        }, Validators.required]);

        this.valueGroups.push(valueGroup);

        if (value) {
            const propertyValue = PropertyControlComponent.getPropertyValueModel(value?.property, this.ruleProperties, [],
                ValuesFormComponent.PropertyControlOptions);
            valueGroup.patchValue({
                tag: this.tags.find((tag) => tag.getValue() === value.tag)?.getValue(),
                property: propertyValue,
                format: value.format,
                formatString: value.formatString
            }, {emitEvent: false});
        }
    }

    public addNewConditionGroup(): void {
        const newConditionGroup = new ConditionGroup();
        this.addConditionGroup(null, newConditionGroup);
        this.conditionGroups.addGroup(newConditionGroup);
    }

    public deleteValueClicked(index: number) {
        this.valueGroups.removeAt(index);
    }

    /**
     * @override
     * @protected
     */
    protected abstract saveRule(): void;
}
