import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';

export interface ICustomPackageFormGroup {
    NAME: FormControl<string>;
    DESCRIPTION: FormControl<string>;
    FORMAT: FormControl<string>;
    SIZE: FormGroup<{
        scaleType: FormControl<EScaleTypes>;
        width: FormControl<string>;
        height: FormControl<string>;
    }>
}

export enum EScaleTypes {
    BOX_SIZE = 'BOX_SIZE',
    SCALE = 'SCALE'
}

@Component({
    selector: 'workflow-configuration-action-custom-package-form',
    templateUrl: 'workflow-configuration-action-custom-package-form.component.html',
    styleUrls: ['workflow-configuration-action-custom-package-form.component.scss'],
    standalone: false
})
export class WorkflowConfigurationActionCustomPackageFormComponent {
    @Input() public scaleTypes: DropdownItem<string>[];
    @Input() public formatOptions: DropdownItem<string>[];
    @Input() public formGroup: FormGroup<ICustomPackageFormGroup>;

    protected readonly EScaleTypes = EScaleTypes;
}
