import {Component, computed, DestroyRef, inject, input, OnInit, Signal, signal} from '@angular/core';
import {CommonModule, NgStyle} from '@angular/common';
import {PipesModule} from '../../../../../../../../../pipes/pipes.module';
import {StickyNotesDataService} from '../../../preview-sticky-notes-sidebar/sticky-notes-data.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {StickyNoteModel} from '../../../../../../../../../models/api/sticky-note.model';
import {PublicationItemModel} from '../../../../../../../../../models/api/publication-item.model';
import {EStickyNoteStatus} from '../../../../../../../../../app.enums';

class StackedNote {
    public stickyNotes: StickyNoteModel[] = [];
    public count = 0;
    public start: number;
    public duration: number;

    public get startPosition(): number {
        return this.duration ? 100 * this.start / this.duration : 0;
    }
}

@Component({
    selector: 'sticky-note-timeline',
    imports: [
        PipesModule,
        NgStyle,
        CommonModule
    ],
    templateUrl: './sticky-note-timeline.component.html',
    styleUrl: './sticky-note-timeline.component.scss'
})
export class StickyNoteTimelineComponent implements OnInit {
    public readonly EStickyNoteStatus = EStickyNoteStatus;

    // Inputs
    public item = input<PublicationItemModel>();
    public notes = input<StickyNoteModel[]>();
    public currentTime = input<number>();
    public duration = input<number>();

    public selectedStickyNote = signal<StickyNoteModel>(null);

    // Recreate stacked notes (stacked on same start position)
    public stackedNotes: Signal<StackedNote[]> = computed(() => {
        if (!this.duration()) return [];

        const notes = Array.from(this.notes() || []);

        const stackedNotes: StackedNote[] = [];
        // Add NEW note to the notes collection
        if (this.selectedStickyNote()?.status === EStickyNoteStatus.NEW) {
            notes.push(this.selectedStickyNote())
        }

        for (const note of notes) {
            let videoNote = stackedNotes.find(videoNote => videoNote.start === note.timeRange.start);
            if (!videoNote) {
                videoNote = new StackedNote();
                stackedNotes.push(videoNote);
                videoNote.start = note.timeRange.start;
                videoNote.duration = this.duration();
            }
            videoNote.stickyNotes.push(note);
            videoNote.count++;
        }

        return stackedNotes;
    });

    private stickyNotesDataService: StickyNotesDataService = inject(StickyNotesDataService);
    private destroyRef: DestroyRef = inject(DestroyRef);

    public ngOnInit(): void {
        this.stickyNotesDataService.selectedStickyNote$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((stickyNote: StickyNoteModel) => {
                this.selectedStickyNote.set(stickyNote);
            });
    }

    public onStickyNoteClicked(event: MouseEvent, stickyNote: StickyNoteModel): void {
        event.stopPropagation();
        this.stickyNotesDataService.setSelectedStickyNote(stickyNote);
    }
}
