import {Component, DestroyRef, Inject, inject, OnInit} from '@angular/core';
import {TabBarItemModel} from '../../models/ui/tab-bar-item.model';
import {FormArray, FormControl, FormControlStatus, FormGroup, Validators} from '@angular/forms';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormArrayMinLengthValidator} from '../../classes/validators/form-array-min-length.validator';
import {distinctUntilChanged} from 'rxjs/operators';
import {
    EProductAssetExportSetupJobType,
    ProductAssetExportSetupsService
} from '../../api/services/product-asset-export-setups.service';
import {ModelUtil} from '../../classes/model.util';
import {ProductAssetExportSetupModel} from '../../models/api/product-asset-export-setup.model';
import {Observable} from 'rxjs';
import {JobModel} from '../../models/api/job.model';
import {Toaster} from '../../classes/toaster.class';
import {EFormStatus} from '../../app.enums';

class ExportFormatForm {
    format: FormControl<string>;
    scaleType: FormControl<string>;
    width: FormControl<number>;
    height: FormControl<number>;
}

class SortItemForm {
    _id?: FormControl<string>;
    label: FormControl<string>
}

class ProductAssetExportSetupForm {
    information: FormGroup<{
        name: FormControl<string>;
        description: FormControl<string>
    }>;
    sortItems: FormArray<FormGroup<SortItemForm>>;
    exportFormat: FormGroup<ExportFormatForm>;
}

interface IProductAssetExportSetupFormData {
    productAssetExportSetup?: ProductAssetExportSetupModel
}

@Component({
    selector: 'product-asset-export-setup-form',
    templateUrl: './product-asset-export-setup-form.component.html',
    styleUrl: './product-asset-export-setup-form.component.scss',
    standalone: false
})
export class ProductAssetExportSetupFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    private productAssetExportSetup: ProductAssetExportSetupModel;

    // full modal related
    private confirmButton: ButtonConfig;
    private confirmAction: FullModalActionModel;
    private onConfirmClicked = this.nextAction;

    // tab related
    public readonly informationTab = new TabBarItemModel('Information', 0);
    public readonly sortingItemsTab = new TabBarItemModel('Sorting items', 1);
    public readonly exportFormatTab = new TabBarItemModel('Export format', 2);
    public readonly tabBarItems: TabBarItemModel[] = [this.informationTab, this.sortingItemsTab, this.exportFormatTab];
    private _activeTab = this.tabBarItems[0];

    get activeTab(): TabBarItemModel {
        return this._activeTab;
    }

    set activeTab(tab: TabBarItemModel) {
        if (tab !== this._activeTab) {
            const index = this.tabBarItems.find((t) => t.title === tab.title).index;
            this._activeTab = tab;
            this._activeTab.index = index;
            this.setConfirmButton();
            this.confirmButton.disabled = (tab === this.exportFormatTab && this.formGroup.status !== EFormStatus.VALID);
        }
    }

    // form related
    public formGroup: FormGroup<ProductAssetExportSetupForm>;
    public scaleTypes = [new DropdownItem('Box size', 'BOX_SIZE')];
    public formatOptions = ['PNG', 'JPG', 'JPEG', 'TIF', 'TIFF', 'GIF'].map((v) => new DropdownItem(v, v));

    constructor(private fullModalService: FullModalService,
                private productAssetExportSetupsService: ProductAssetExportSetupsService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IProductAssetExportSetupFormData) {
        this.productAssetExportSetup = this.modalData?.productAssetExportSetup || new ProductAssetExportSetupModel();

    }

    public ngOnInit(): void {
        this.initForm();
        this.initButtons();
    }

    private initForm(): void {
        const sortItemsControls = (this.productAssetExportSetup.sortItems || [])
            .map((item) => new FormGroup<SortItemForm>(
                {
                    _id: new FormControl(item._id),
                    label: new FormControl(item.label)
                }
            ));

        this.formGroup = new FormGroup<ProductAssetExportSetupForm>({
            information: new FormGroup({
                name: new FormControl(this.productAssetExportSetup.name, Validators.required),
                description: new FormControl(this.productAssetExportSetup.description)
            }),
            sortItems: new FormArray(sortItemsControls, FormArrayMinLengthValidator(1)),
            exportFormat: new FormGroup<ExportFormatForm>({
                format: new FormControl(this.productAssetExportSetup.format, Validators.required),
                scaleType: new FormControl(this.productAssetExportSetup.scaleType, Validators.required),
                width: new FormControl(this.productAssetExportSetup.width, [Validators.required, Validators.min(1), Validators.max(20000)]),
                height: new FormControl(this.productAssetExportSetup.height, [Validators.required, Validators.min(1), Validators.max(20000)])
            })
        });
    }

    private initButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.confirmButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Next', null, null, false);

        const cancelAction = new FullModalActionModel(cancelButton);
        this.confirmAction = new FullModalActionModel(this.confirmButton);

        cancelAction.observable.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.fullModalService.close(false, true));
        this.confirmAction.observable.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.onConfirmClicked());

        const actions = [cancelAction, this.confirmAction];
        this.fullModalService.setModalActions(actions);

        this.formGroup.statusChanges
            .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
            .subscribe((status: FormControlStatus) => {
                if (this.activeTab === this.exportFormatTab) this.confirmButton.disabled = status !== EFormStatus.VALID;
            });
    }

    private setConfirmButton(): void {
        switch (this._activeTab) {
            case this.informationTab:
            case this.sortingItemsTab:
                this.confirmButton.text = 'Next';
                this.onConfirmClicked = this.nextAction;
                return;
            case this.exportFormatTab:
                this.confirmButton.text = 'Save';
                this.onConfirmClicked = this.saveAction;
                break;
        }
    }

    private nextAction(): void {
        this.activeTab = this.tabBarItems.find((tab) => tab.index === this.activeTab.index + 1);
    }

    private saveAction(): void {
        const setup = ModelUtil.createApiBody(
            {
                ...this.formGroup.controls.information.value,
                sortItems: this.formGroup.controls.sortItems.controls.map((control) => control.value),
                scaleType: this.formGroup.controls.exportFormat.value.scaleType,
                format: this.formGroup.controls.exportFormat.value.format,
                width: this.formGroup.controls.exportFormat.value.width,
                height: this.formGroup.controls.exportFormat.value.height
            }, this.productAssetExportSetup._id);

        this.confirmButton.loading = true;
        const observable: Observable<JobModel|ProductAssetExportSetupModel> = this.productAssetExportSetup._id
            ? this.productAssetExportSetupsService.postJob(
                EProductAssetExportSetupJobType.EDIT_PRODUCT_ASSET_EXPORT_SETUP_JOB,
                {productAssetExportSetupId: this.productAssetExportSetup._id, body: setup})
            : this.productAssetExportSetupsService.create(setup);

        observable
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                error: (error) => {
                    Toaster.handleApiError(error);
                    this.confirmButton.loading = false;
                },
                next: (result) => {
                    if (this.productAssetExportSetup._id) {
                        Toaster.success('Product asset export setup added successfully');
                    }
                    this.fullModalService.close(result);
                }
            });
    }

    public addSortItem(): void {
        this.formGroup.controls.sortItems.push(new FormGroup({label: new FormControl()}));
    }

    public removeSortItem(index: number): void {
        this.formGroup.controls.sortItems.removeAt(index);
    }

    public drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.formGroup.controls.sortItems.controls, event.previousIndex, event.currentIndex);
    }
}
