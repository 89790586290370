import {Component, OnDestroy, OnInit} from '@angular/core';
import {WorkflowConfigurationFilterModel} from '../../../../../../models/api/workflow-configuration-filter.model';
import {WorkflowConfigurationFilterOptionModel} from '../../../../../../models/api/workflow-configuration-filter-option.model';
import {WorkflowConfigurationModel} from '../../../../../../models/api/workflow-configuration.model';
import {PublicationModel} from '../../../../../../models/api/publication.model';
import {combineLatest, Subject} from 'rxjs';
import {switchMap, take, takeUntil} from 'rxjs/operators';
import {CustomWorkflowService} from '../custom-workflow.service';
import {TemporaryStorageUtil} from '../../../../../../classes/rl-temporary-storage.util';
import {DataFilterModel} from '../../../../../../models/ui/data-filter.model';
import {EDataCollectionName, EDataFieldTypes} from '../../../../../../app.enums';
import {AdvancedFiltersDataService} from '../../../../../../api/services/advanced-filters.data-service';
import {DropdownItem} from '../../../../../../models/ui/dropdown-item.model';
import {VariantModel} from '../../../../../../models/api/variant.model';

// TODO: refactor the workflow service to use the advancedFiltersDataService directly and add session storage
// option in data-filter. workflow-component can then add data-filter component directly and this component can be removed.
@Component({
    selector: 'rl-custom-workflow-filters',
    templateUrl: './custom-workflow-filters.component.html',
    providers: [AdvancedFiltersDataService],
    standalone: false
})
export class CustomWorkflowFiltersComponent implements OnInit, OnDestroy {

    public publication: PublicationModel;
    public workflowConfiguration: WorkflowConfigurationModel;
    public variant: VariantModel;

    private onDestroySubject = new Subject<void>();

    public dataFilters: DataFilterModel[] = [];

    constructor(private customWorkflowService: CustomWorkflowService,
                private temporaryStorageUtil: TemporaryStorageUtil,
                private advancedFiltersDataService: AdvancedFiltersDataService) {
    }

    public ngOnInit() {
        combineLatest([
            this.customWorkflowService.workflowConfiguration$,
            this.customWorkflowService.publication$])
            .pipe(
                take(1),
                switchMap(([workflowConfiguration, publication]) => {
                    this.workflowConfiguration = workflowConfiguration;
                    this.publication = publication;

                    this.dataFilters = [];
                    for (const filter of workflowConfiguration.filters) {
                        const items = filter.items?.map(item => new DropdownItem<string>(item.title, item.value));
                        const dataFieldType = filter.dataType || EDataFieldTypes.LIST;

                        this.dataFilters.push(new DataFilterModel(
                            filter.name,
                            filter.queryParam,
                            dataFieldType,
                            items,
                            null,
                            {publication: this.publication._id, campaign: this.customWorkflowService.campaign._id},
                            EDataCollectionName.PUBLICATION_ITEM,
                            filter.custom ? filter._id : null
                        ));
                    }

                    // listen for filter changes, so we can transfer the data and store in the temporaryStorageUtil
                    return this.advancedFiltersDataService.getFilterValues();
                }),
                takeUntil(this.onDestroySubject)
            )
            .subscribe((values) => {
                    const activeFilters = new Map<WorkflowConfigurationFilterModel, WorkflowConfigurationFilterOptionModel[]>();

                    for (const filter of this.workflowConfiguration.filters) {
                        const index = Object.keys(values).find(v => v === filter.queryParam);

                        if (index) {
                            if (Array.isArray(values[index])) {
                                activeFilters.set(filter, values[index].map(v =>
                                    new WorkflowConfigurationFilterOptionModel(v, v)
                                ));
                            } else {
                                activeFilters.set(filter, [new WorkflowConfigurationFilterOptionModel(values[index], values[index])]);
                            }
                        } else {
                            activeFilters.delete(filter);
                        }
                    }

                    this.customWorkflowService.setActiveFilters(activeFilters);
                });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }
}
