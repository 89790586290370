import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {PackageRuleModel} from '../../../models/api/package-setup.model';
import {RLValidatorConstants} from '../../../classes/validators/rl-validators.constant';
import {ETabFormGroup} from '../package-rule-form.component';

@Component({
    selector: 'package-rule-information-form-component',
    templateUrl: 'package-rule-information-form.component.html',
    styleUrls: ['package-rule-information-form.component.scss'],
    standalone: false
})

export class PackageRuleInformationFormComponent implements OnInit {
    @Input() public form: UntypedFormGroup;
    @Input() public packageRule: PackageRuleModel;

    public formGroup: UntypedFormGroup;

    private readonly FORM_GROUP_NAME = ETabFormGroup.PACKAGE_RULE_INFORMATION;

    public ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        if (!this.form.contains(this.FORM_GROUP_NAME)) {
            this.formGroup = new UntypedFormGroup({
                name: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
            });
            this.form.addControl(this.FORM_GROUP_NAME, this.formGroup);

            if (this.packageRule) {
                const data = {
                    name: this.packageRule.name
                };
                this.formGroup.patchValue(data);
            }
        } else {
            this.formGroup = this.form.get(this.FORM_GROUP_NAME) as UntypedFormGroup;
            this.packageRule = this.formGroup.value;
        }
    }
}
