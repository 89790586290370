import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {
    EColumnType,
    ESelectionMode,
    IActionClickEvent,
    ITableColumn,
    NucPopOutContentService
} from '@relayter/rubber-duck';
import {CampaignItemModel} from '../../../../../../models/api/campaign-item.model';
import {
    BriefingTableComponent,
    IBriefingTableOptions
} from '../../../../../../components/briefing-table/briefing-table.component';
import {CustomWorkflowBaseComponent} from '../custom-workflow-base.component';
import {BriefingActionsService} from '../../../../../../api/services/briefing-actions.service';
import {AppConstants} from '../../../../../../app.constants';
import {Toaster} from '../../../../../../classes/toaster.class';
import {CampaignItemResultModel} from '../../../../../../models/ui/campaign-item-result.model';
import {NewCursorArray} from '../../../../../../api/new-api-cursor';
import {CampaignItemsApiService} from '../../../../../../api/services/campaign-items.api.service';
import {PublicationItemModel} from '../../../../../../models/api/publication-item.model';
import {SelectionModel} from '@angular/cdk/collections';
import {
    EWorkflowConfigurationActionType,
    WorkflowConfigurationActionModel
} from '../../../../../../models/api/workflow-configuration-action.model';
import {UserSettingsStorageService} from '../../../../../../api/services/user-settings-storage.service';
import {PublicationsService} from '../../../../../../api/services/publications.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export enum EBriefingComponentOptions {
    INLINE_EDITING = 'INLINE_EDITING'
}

@Component({
    selector: 'rl-custom-workflow-briefing-component',
    templateUrl: './custom-workflow-briefing.component.html',
    styleUrls: ['./custom-workflow-briefing.component.scss'],
    standalone: false
})
export class CustomWorkflowBriefingComponent extends CustomWorkflowBaseComponent implements OnInit {
    public selectionMode = ESelectionMode.EXPAND;

    public tableId: string;
    public readonly storageKey: string;
    private cursorIndex: number;
    private cursorArray: NewCursorArray;
    public assignableItems: boolean;

    public selection = new SelectionModel<CampaignItemModel>(true);

    @ViewChild(BriefingTableComponent) private briefingTable: BriefingTableComponent;

    public additionalColumns: ITableColumn[] = [];

    public briefingTableOptions: IBriefingTableOptions = {};

    private publicationService = inject(PublicationsService)

    constructor(private userSettingsStorageService: UserSettingsStorageService,
                private briefingActionsService: BriefingActionsService,
                private campaignItemApiService: CampaignItemsApiService,
                popOutService: NucPopOutContentService) {
        super(popOutService);
        this.storageKey = this.userSettingsStorageService.getPrefixStorageKey();
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.assignableItems = this.publication.workflowConfiguration.sequentialNumbering;

        this.briefingActionsService.requestCampaignItem$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.getNextItem();
            });

        this.briefingActionsService.transitionPosted$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((transitionItemId) => {
                this.monitorTransition(transitionItemId);
            });
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        this.resetCursor(event.item as CampaignItemModel);

        const action = this.allowedActions.find((allowedAction) => allowedAction.title === event.action.title);

        if (action.type === EWorkflowConfigurationActionType.TRANSITION_TRIGGER) {
            this.publicationService.postTransitionItem(this.publication._id, action.transition,
                { items: [{ campaignItemIds: [event.item._id] }]})
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({error: Toaster.handleApiError})
        } else {
            const data = {hasNext: this.briefingTable.hasNextItemForCampaignItem(event.item._id)};
            const campaignItem = event.item as CampaignItemModel;
            this.handleAction(action, [event.item as CampaignItemModel], event.origin, data);

            this.getNextItem(campaignItem);
        }
    }

    public handleMultiSelectionAction(action: WorkflowConfigurationActionModel): void {
        if (action.type === EWorkflowConfigurationActionType.TRANSITION_TRIGGER) {
            this.publicationService.postTransitionItem(this.publication._id, action.transition,
                { items: this.selection.selected.map(c=> { return { campaignItemIds: [c._id] } }) })
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({error: Toaster.handleApiError})
        } else {
            this.handleAction(action, this.selection.selected);
            if (this.selection.selected.length === 1) { // selection model single mode
                const campaignItem = this.selection.selected[0];
                this.resetCursor(campaignItem);
                this.getNextItem(campaignItem);
            }
        }

        this.selection.clear();
    }

    protected refreshData(): void {
        this.briefingTable.getCampaignItems();
    }

    protected setupData(): void {
        this.briefingTableOptions.inlineEditing = !!this.allowedActions.find((action) => action.name === EBriefingComponentOptions.INLINE_EDITING);
        // Ensure each use of this generic briefing table has its own config for properties
        this.tableId = `custom-workflow-briefing-${this.component._id}`;
        if (this.publication.workflowConfiguration.sequentialNumbering) {
            this.additionalColumns.push({
                title: 'Assigned to',
                key: 'assignedPublicationItems',
                format: (value) => {
                    const assignedPages: string[] = value.map((item) => {
                        item = Object.assign(new PublicationItemModel(), item);
                        return item.formattedPageNumbers;
                    });
                    return assignedPages.length === 0 ? 'Not assigned' : assignedPages.join(', ');
                }
            });
        } else {
            this.additionalColumns.push({
                title: 'Generated',
                key: 'itemsGenerated',
                type: EColumnType.ICON,
                sortDuplicates: true,
                iconClass: (value) => value > 0 ? 'nucicon_check_round_fill' : '',
                format: (value) => value > 0 ? value : 'No items',
                color: AppConstants.FIRST_BRAND_COLOR,
                sortProperty: 'itemsGenerated'
            });
        }

        this.selectionMode = this.multiCompatibleActions.length > 0 ? ESelectionMode.MULTI : ESelectionMode.EXPAND;
        this.selection.clear();
    }

    private resetCursor(campaignItem: CampaignItemModel): void {
        this.cursorIndex = AppConstants.PAGE_INDEX_DEFAULT;

        this.cursorArray = new NewCursorArray(AppConstants.PAGE_INDEX_DEFAULT, this.briefingTable.tableSortOptions);

        this.cursorArray.setCursor(AppConstants.PAGE_INDEX_DEFAULT, campaignItem);

        this.cursorIndex++;
    }

    public getNextItem(campaignItem?: CampaignItemModel): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_CAMPAIGN_ITEMS)) {
            const cursor = this.cursorArray.getCursor(this.cursorIndex);
            // Limit set on 2 to get also the next item
            this.campaignItemApiService.getCampaignItems(this.campaign._id, this.publication._id,
                2, 0, this.briefingTable.briefingDataProvider.phraseValue, this.briefingTable.briefingDataProvider.sortOptions,
                this.briefingTable.briefingDataProvider.filterValues, cursor)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        const itemsLength = campaignItem ? 1 : 0;

                        if (campaignItem) {
                            result.items.unshift(campaignItem);
                        }

                        if (result.items.length > itemsLength) {
                            // Item at 0, for the cursor to get the correct next items
                            this.cursorArray.setCursor(this.cursorIndex, result.items[0]);
                            this.cursorIndex++;
                        }

                        this.briefingActionsService.setCampaignItem(new CampaignItemResultModel(result.items,
                            result.items.length > 1)); // Has next only when we have at least two items, because the cursor is looking ahead
                    },
                    error: (error) => {
                        Toaster.handleApiError(error);
                        this.briefingActionsService.setCampaignItem(new CampaignItemResultModel([], false));
                    }
                });
        }
    }
}
