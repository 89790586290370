import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {FormControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {PackageExportSettings, PackageSetupModel} from '../../../models/api/package-setup.model';
import {ETabFormGroup} from '../package-setup-form.component';
import {Toaster} from '../../../classes/toaster.class';
import {LayoutsService} from '../../../api/services/layouts.service';
import {LayoutModel} from '../../../models/api/layout.model';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {RLValidatorConstants} from '../../../classes/validators/rl-validators.constant';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {StringUtil} from '../../../classes/string-util';

@Component({
    selector: 'package-setup-export-settings-form-component',
    templateUrl: 'package-setup-export-settings-form.component.html',
    styleUrls: ['package-setup-export-settings-form.component.scss'],
    standalone: false
})

export class PackageSetupExportSettingsFormComponent implements OnInit {
    private destroyRef: DestroyRef = inject(DestroyRef);

    @Input() public form: UntypedFormGroup;
    @Input() public packageSetup: PackageSetupModel;
    public readonly VALIDATOR_MESSAGES = RLValidatorConstants.MESSAGES;

    public layouts: LayoutModel[];
    public packageExportSettingsGroup: UntypedFormGroup;
    public sortByOptions: IDropdownItem[] = [];
    public fieldOptions: IDropdownItem[] = [];

    private materialDataFields = [];
    private campaignDataFields = [];

    constructor(private layoutService: LayoutsService) {}

    public ngOnInit(): void {
        this.initData();
    }

    private initData(): void {
        this.layoutService.find().pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
            next: (layoutsResult) => {
                this.layouts = layoutsResult.items;
                this.initForm();
            },
            error: Toaster.handleApiError
        });
    }

    private initForm(): void {
        if (!this.form.contains(ETabFormGroup.PACKAGE_EXPORT_SETTINGS)) {
            this.packageExportSettingsGroup = new UntypedFormGroup({
                layout: new FormControl(),
                sortBy: new FormControl(),
                multiplyItemsByField: new FormControl(),
                addInsertsFor:  new FormControl(),
                addTitlePage:  new FormControl()
            }, this.validateLayoutRequired);
            this.form.addControl(ETabFormGroup.PACKAGE_EXPORT_SETTINGS, this.packageExportSettingsGroup);

            if (this.packageSetup.exportSettings) {
                const exportSettings = this.packageSetup.exportSettings as PackageExportSettings;
                const data = {
                    [ETabFormGroup.PACKAGE_EXPORT_SETTINGS]: {
                        layout: (exportSettings.layout as LayoutModel)?._id,
                        sortBy: exportSettings.sortBy.map(field => {
                            return new DropdownItem(field, field);
                        }),
                        multiplyItemsByField: exportSettings.multiplyItemsByField,
                        addInsertsFor: exportSettings.addInsertsFor,
                        addTitlePage: exportSettings.addTitlePage
                    }
                };
                this.form.patchValue(data);
            }
        } else {
            this.packageExportSettingsGroup = this.form.get(ETabFormGroup.PACKAGE_EXPORT_SETTINGS) as UntypedFormGroup;
        }

        if (this.form.contains(ETabFormGroup.PACKAGE_DATA)) {
            // get the data from the form (could be changed by user)
            this.campaignDataFields = this.form.get(ETabFormGroup.PACKAGE_DATA).value.campaignDataFields;
            this.materialDataFields = this.form.get(ETabFormGroup.PACKAGE_DATA).value.materialDataFields;
        } else {
            this.campaignDataFields = this.packageSetup.campaignDataFields;
            this.materialDataFields = this.packageSetup.materialDataFields;
        }

        this.setFieldOptions();
    }

    private validateLayoutRequired: ValidatorFn = (formGroup: UntypedFormGroup): null => {
        // if any of the other inputs have value then layout is required
        const layout = formGroup.get('layout');
        const sortBy = formGroup.get('sortBy').value;
        const multiplyItemsByField = formGroup.get('multiplyItemsByField').value;
        const addInsertsFor = formGroup.get('addInsertsFor').value;
        const addTitlePage = formGroup.get('addTitlePage').value;
        if ((sortBy?.length > 0 || addInsertsFor?.length > 0 || multiplyItemsByField !== null || addTitlePage)
            && !layout?.value) {
            formGroup.get('layout').setErrors({layoutRequired: true});
            formGroup.get('layout').markAsDirty();
        } else {
            formGroup.get('layout').setErrors(null);
        }
        return;
    };

    public onSortBySearched(search: string): void {
        if (search) {
            const regex = new RegExp(StringUtil.escapeRegExp(search), 'i');
            this.sortByOptions = this.fieldOptions.filter((field) =>
                field.getTitle().match(regex)?.length > 0)
        } else {
            this.sortByOptions = this.fieldOptions;
        }
    }

    private setFieldOptions(): void {
        const fields = [
            ... this.campaignDataFields.map(field => field.name),
            ... this.materialDataFields.map(field => field.name)];

        // sort alphabetically
        fields.sort((a, b) => a.localeCompare(b));

        this.fieldOptions = fields.map(field => {
            return new DropdownItem(field, field);
        });
        this.sortByOptions = this.fieldOptions;
    }
}
